define("frontend/components/schedule-modal/component", ["exports", "@ember-decorators/component", "semantic-ui-ember/components/ui-modal"], function (_exports, _component, _uiModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var ScheduleModal = (_dec = (0, _component.classNames)("schedule"), _dec2 = Ember.computed("event.employee.content"), _dec(_class = (_class2 = (_temp = class ScheduleModal extends _uiModal.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "name", "schedule");
    }

    get isSaveButtonDisabled() {
      var hasNoEmployee = Ember.isEmpty(this.get("event.employee.content"));
      return hasNoEmployee;
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "isSaveButtonDisabled", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "isSaveButtonDisabled"), _class2.prototype)), _class2)) || _class);
  _exports.default = ScheduleModal;
});