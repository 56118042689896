define("frontend/components/x-item/stock-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "efVyRuU3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"item\",\"unlimited\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"undo icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[\"ui label \",[28,\"if\",[[23,0,[\"item\",\"lowStock\"]],\"red\",\"green\"],null]]]],[11,\"title\",[23,0,[\"title\"]]],[8],[0,\"\\n    \"],[1,[23,0,[\"roundedCount\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/components/x-item/stock-icon/template.hbs"
    }
  });

  _exports.default = _default;
});