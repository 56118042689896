define("frontend/visit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qp6CRJgU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui grid hidden-print\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"twelve wide column\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"customer.show\",[23,0,[\"model\",\"customer\",\"id\"]]]],{\"statements\":[[0,\"\\n        \"],[1,[23,0,[\"model\",\"customer\",\"fullName\"]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"x-note\",[],[[\"@note\",\"@onSave\"],[[23,0,[\"model\",\"customer\",\"note\"]],[28,\"action\",[[23,0,[]],\"updateCustomer\"],null]]]],[0,\"\\n    \"],[5,\"x-customer/secret-form\",[],[[\"@customer\",\"@field\",\"@onSave\"],[[23,0,[\"model\",\"customer\"]],\"mail\",[28,\"action\",[[23,0,[]],\"updateCustomer\"],null]]]],[0,\"\\n    \"],[5,\"x-customer/secret-form\",[],[[\"@customer\",\"@field\",\"@onSave\"],[[23,0,[\"model\",\"customer\"]],\"phone\",[28,\"action\",[[23,0,[]],\"updateCustomer\"],null]]]],[0,\"\\n    \"],[5,\"x-customer/secret-form\",[],[[\"@customer\",\"@field\",\"@onSave\"],[[23,0,[\"model\",\"customer\"]],\"birth\",[28,\"action\",[[23,0,[]],\"updateCustomer\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"four wide column\"],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"class\",\"ui tiny circular bordered image floated right\"],[11,\"src\",[23,0,[\"model\",\"employee\",\"photoUrl\"]]],[11,\"alt\",[23,0,[\"model\",\"employee\",\"fullName\"]]],[8],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui divider hidden-print\"],[8],[9],[0,\"\\n\"],[1,[28,\"liquid-outlet\",null,[[\"containerless\",\"class\"],[true,\"ui centered grid\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/visit/template.hbs"
    }
  });

  _exports.default = _default;
});