define("frontend/components/x-item/type/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var Type = (_dec = (0, _component.tagName)("i"), _dec2 = (0, _component.classNames)("icon"), _dec3 = (0, _component.classNameBindings)("iconBinding"), _dec4 = Ember.computed("item.isService"), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class Type extends Ember.Component {
    get iconBinding() {
      if (this.get("item.isService")) {
        return "cut";
      } else {
        return "gift";
      }
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "iconBinding", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "iconBinding"), _class2.prototype)), _class2)) || _class) || _class) || _class);
  _exports.default = Type;
});