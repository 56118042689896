define("frontend/utils/add-vat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = addVat;

  function addVat(purchasePrice, vat) {
    return purchasePrice * (1 + vat / 100);
  }
});