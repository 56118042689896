define("frontend/application/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  var ApplicationController = (_dec = Ember.computed.filterBy("allVisits", "completed", false), _dec2 = Ember.computed.equal("session.data.authenticated.role", "admin"), _dec3 = Ember.computed.oneWay("customers.birthdayInWeek.length"), (_class = (_temp = class ApplicationController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "preference", _descriptor2, this);

      _initializerDefineProperty(this, "customers", _descriptor3, this);

      _initializerDefineProperty(this, "openVisits", _descriptor4, this);

      _initializerDefineProperty(this, "isAdmin", _descriptor5, this);

      _initializerDefineProperty(this, "noOfBirthdaysInWeek", _descriptor6, this);
    }

    get allVisits() {
      return this.store.peekAll("visit");
    }

    toggleMe(selector) {
      Ember.$(selector).sidebar("toggle");
    }

    logMeOut() {
      this.session.invalidate();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "preference", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "customers", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "allVisits", [Ember.computed], Object.getOwnPropertyDescriptor(_class.prototype, "allVisits"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "openVisits", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isAdmin", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "noOfBirthdaysInWeek", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleMe", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleMe"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "logMeOut", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "logMeOut"), _class.prototype)), _class));
  _exports.default = ApplicationController;
});