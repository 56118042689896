define("frontend/components/visit-revenue-sum/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var VisitRevenueSum = (_dec = Ember.computed("revenues.[]", "type"), (_class = class VisitRevenueSum extends Ember.Component {
    get sum() {
      var {
        type
      } = this;
      return this.revenues.reduce((prev, curr) => {
        return prev + curr.get(type);
      }, 0);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "sum", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "sum"), _class.prototype)), _class));
  _exports.default = VisitRevenueSum;
});