define("frontend/models/company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    isInvoicePrintingActive: _emberData.default.attr("boolean"),
    invoiceLogo: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    cashbookBalance: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    address: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    website: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    facebook: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    facebookTitle: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    instagram: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    instagramTitle: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    phone: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    phoneLandline: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    email: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    expiryDate: _emberData.default.attr("date", {
      defaultValue: () => new Date()
    }),
    payments: _emberData.default.hasMany("payment"),
    willNotifyReservations: _emberData.default.attr("boolean"),
    reservationNotificationTemplate: _emberData.default.attr("string", {
      defaultValue: 'Dobry den, pripominame Vasi navstevu naseho salonu dne %{date} v %{time}. S pozdravem %{company_name}, %{company_phone}'
    }),
    note: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    entities: _emberData.default.hasMany("entity"),
    sells: _emberData.default.hasMany(""),
    reservations: _emberData.default.hasMany(),
    notifications: _emberData.default.hasMany("")
  });

  _exports.default = _default;
});