define("frontend/customers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hqEwpTO+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"customers.all\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"link-to\",[[12,\"class\",\"ui primary button\"]],[[\"@route\"],[\"customer.new\"]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"t\",[\"customers.newCustomer\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[7,\"hr\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n\"],[5,\"data-grid\",[],[[\"@columns\",\"@rows\",\"@sort\",\"@fillColumnIndex\",\"@onUpdateSort\",\"@onRowClick\",\"@onFulltextFilter\"],[[23,0,[\"columns\"]],[23,0,[\"rows\"]],[23,0,[\"sort\"]],2,[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"sort\"]]],null]],null],[28,\"action\",[[23,0,[]],\"rowClicked\"],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"filter\"]]],null]],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/customers/template.hbs"
    }
  });

  _exports.default = _default;
});