define("frontend/components/version-checker/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  var VersionChecker = (_dec = (0, _component.tagName)(""), _dec(_class = class VersionChecker extends Ember.Component {}) || _class);
  _exports.default = VersionChecker;
});