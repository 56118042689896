define("frontend/components/x-item/stock-icon/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var StockIcon = (_dec = (0, _component.tagName)(""), _dec2 = Ember.computed("item.warningThreshold"), _dec3 = Ember.computed("item.stock"), _dec(_class = (_class2 = class StockIcon extends Ember.Component {
    get title() {
      var warningThreshold = this.get("item.warningThreshold");
      return "min: ".concat(warningThreshold);
    }

    get roundedCount() {
      return this.getWithDefault("item.stock", 0).toFixed(2);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "title", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "title"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "roundedCount", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "roundedCount"), _class2.prototype)), _class2)) || _class);
  _exports.default = StockIcon;
});