define("frontend/calendar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hiAhzKzK",
    "block": "{\"symbols\":[\"employee\"],\"statements\":[[7,\"h2\",true],[8],[1,[28,\"t\",[\"calendar.header\"],null],false],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui top attached tabular menu\"],[8],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\",\"@model\"],[\"calendar.employee\",\"all\"]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"calendar.allEmployees\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"],[4,\"each\",[[23,0,[\"model\",\"employees\",\"active\"]]],null,{\"statements\":[[0,\"    \"],[5,\"link-to\",[[12,\"class\",[28,\"concat\",[\"item\",\" \",[23,1,[\"color\"]]],null]]],[[\"@route\",\"@model\"],[\"calendar.employee\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[23,1,[\"fullName\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui bottom attached active tab segment\"],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/calendar/template.hbs"
    }
  });

  _exports.default = _default;
});