define("frontend/admin/notifications/controller", ["exports", "moment", "frontend/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var NotificationsController = (_dec = Ember.computed.oneWay("model"), (_class = (_temp = class NotificationsController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", ["from", "to"]);

      _defineProperty(this, "from", null);

      _defineProperty(this, "to", null);

      _defineProperty(this, "page", 1);

      _defineProperty(this, "perPage", 10);

      _initializerDefineProperty(this, "rows", _descriptor, this);
    }

    get columns() {
      return [{
        name: this.intl.t("models.notification.sendAt"),
        valuePath: "sendAt",
        cellComponent: "x-date/long",
        width: 150
      }, {
        name: this.intl.t("models.notification.phone"),
        valuePath: "phone",
        width: 140
      }, {
        name: this.intl.t("models.notification.message"),
        valuePath: "message"
      }, {
        name: this.intl.t("models.notification.status"),
        valuePath: "status",
        cellComponent: "x-notification/status",
        width: 80
      }];
    }

    updateDates(startDate, endDate) {
      this.setProperties({
        from: (0, _moment.default)(startDate).format(_environment.default.app.dateFormat),
        to: (0, _moment.default)(endDate).format(_environment.default.app.dateFormat)
      });
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "columns", [Ember.computed], Object.getOwnPropertyDescriptor(_class.prototype, "columns"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "rows", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateDates", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "updateDates"), _class.prototype)), _class));
  _exports.default = NotificationsController;
});