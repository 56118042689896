define("frontend/visit/checkout/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  var CheckoutController = (_dec = Ember.computed.oneWay("settings.isInvoicePrintingActive"), _dec2 = Ember.computed("model.{paidByCard,priceWithTip,receivedCash}"), _dec3 = Ember.computed("returnCash"), _dec4 = Ember.computed("model.{priceWithTip,price}"), _dec5 = Ember.computed("isReturnCashInvalid"), (_class = (_temp = class CheckoutController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "settings", _descriptor, this);

      _initializerDefineProperty(this, "isInvoicePrintingActive", _descriptor2, this);
    }

    get returnCash() {
      var receivedCash = parseInt(this.get("model.receivedCash")) || 0;
      var paidByCard = parseInt(this.get("model.paidByCard")) || 0;
      var priceWithTip = parseInt(this.get("model.priceWithTip")) || 0;
      return receivedCash + paidByCard - priceWithTip;
    }

    get isReturnCashInvalid() {
      var isAnyEmpty = ["receivedCash", "paidByCard", "priceWithTip"].any(item => {
        return Ember.isEmpty(this.get("model.".concat(item)));
      });
      var {
        returnCash
      } = this;
      return returnCash < 0 || isAnyEmpty;
    }

    get priceWithTipClass() {
      var priceWithTip = this.get("model.priceWithTip");
      var price = this.get("model.price");

      if (priceWithTip < price) {
        return "error";
      }

      return "";
    }

    get returnCashClass() {
      var {
        isReturnCashInvalid
      } = this;

      if (isReturnCashInvalid) {
        return "error";
      } else {
        return "green";
      }
    }

    confirmCheckout() {
      var priceWithTip = parseInt(this.get("model.priceWithTip"));
      var paidByCard = parseInt(this.get("model.paidByCard"));
      var paidInCash = priceWithTip - paidByCard;
      var visit = this.model;
      visit.set("paidInCash", paidInCash);
      visit.set("completed", true);
      var flash = Ember.get(this, "flashMessages");
      visit.save().then(() => {
        flash.success("Successfully saved!");

        if (this.isInvoicePrintingActive) {
          this.transitionToRoute("visit.print", visit.get("id"));
        } else {
          this.transitionToRoute("visits");
        }
      }, function (response) {
        flash.danger("Something went wrong!", response);
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "settings", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isInvoicePrintingActive", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "returnCash", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "returnCash"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isReturnCashInvalid", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isReturnCashInvalid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "priceWithTipClass", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "priceWithTipClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "returnCashClass", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "returnCashClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirmCheckout", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "confirmCheckout"), _class.prototype)), _class));
  _exports.default = CheckoutController;
});