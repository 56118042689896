define("frontend/models/reservation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    start: _emberData.default.attr("date"),
    end: _emberData.default.attr("date"),
    note: _emberData.default.attr("string"),
    willNotify: _emberData.default.attr("boolean"),
    customer: _emberData.default.belongsTo("customer", {
      async: true
    }),
    employee: _emberData.default.belongsTo("employee", {
      async: true
    }),
    notification: _emberData.default.hasMany("notification", {
      polymorphic: true
    })
  });

  _exports.default = _default;
});