define("frontend/registration/terms/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ud42G5WV",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"markdown-to-html\",[[23,0,[\"textWithReplacements\"]]],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/registration/terms/template.hbs"
    }
  });

  _exports.default = _default;
});