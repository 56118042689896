define("frontend/components/entity-sum-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HGlx2uya",
    "block": "{\"symbols\":[\"@entity\",\"@revenues\"],\"statements\":[[7,\"td\",true],[8],[0,\"\\n  \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"right aligned\"],[8],[0,\"\\n  \"],[5,\"sell-entity-sum\",[],[[\"@type\",\"@entity\",\"@revenues\"],[\"amountServices\",[23,1,[]],[23,2,[]]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"right aligned\"],[8],[0,\"\\n  \"],[5,\"sell-entity-sum\",[],[[\"@type\",\"@entity\",\"@revenues\"],[\"amountSales\",[23,1,[]],[23,2,[]]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"right aligned\"],[8],[0,\"\\n  \"],[5,\"sell-entity-sum\",[],[[\"@type\",\"@entity\",\"@revenues\"],[\"amount\",[23,1,[]],[23,2,[]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/components/entity-sum-row/template.hbs"
    }
  });

  _exports.default = _default;
});