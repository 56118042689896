define("frontend/cashbook/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  var CashbookController = (_dec = Ember.computed.equal("session.data.authenticated.role", "admin"), _dec2 = Ember.computed.oneWay("settings.cashbookBalance"), _dec3 = Ember.computed.oneWay("model.entries"), _dec4 = (0, _emberConcurrency.task)(function* (newEntry) {
    var flash = this.flashMessages;
    newEntry.set("touchedAt", new Date());
    yield newEntry.save().then(() => {
      flash.success(this.intl.t("api.200"));
    }, response => {
      flash.danger(this.intl.t("api.500", {
        message: response.message
      }));
    }).finally(() => {
      return this.send("refreshMe");
    });
  }), (_class = (_temp = class CashbookController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", ["sort"]);

      _defineProperty(this, "sort", "-touchedAt");

      _initializerDefineProperty(this, "settings", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "isAdmin", _descriptor3, this);

      _initializerDefineProperty(this, "cashbookBalance", _descriptor4, this);

      _initializerDefineProperty(this, "rows", _descriptor5, this);

      _initializerDefineProperty(this, "persistNewEntry", _descriptor6, this);
    }

    get columns() {
      var i18n = this.intl;
      return [{
        name: i18n.t("models.cashbookEntry.touchedAt"),
        cellComponent: "x-date/long",
        valuePath: "touchedAt",
        textAlign: "right",
        width: 180
      }, {
        name: i18n.t("models.cashbookEntry.amount"),
        cellComponent: "money",
        valuePath: "amount",
        textAlign: "right",
        width: 130
      }, {
        name: i18n.t("models.cashbookEntry.note"),
        valuePath: "note"
      }, {
        name: i18n.t("models.cashbookEntry.kind"),
        cellComponent: "data-grid/cashbook/kind",
        onClick: this.actions.deleteCashbookEntry.bind(this),
        valuePath: "kind",
        textAlign: "right",
        width: 130
      }];
    }

    deleteCashbookEntry(entry) {
      var flash = this.flashMessages;
      entry.deleteRecord();
      return entry.save().then(() => {
        flash.success(this.intl.t("api.200"));
      }, response => {
        flash.danger(this.intl.t("api.500", {
          message: response.message
        }));
      }).finally(() => {
        this.send("refreshMe");
      });
    }

    rowClicked(row) {
      if (row.rowValue.visit) {
        this.transitionToRoute("admin.visit", row.rowValue.get("visit.id"));
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "settings", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isAdmin", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "cashbookBalance", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "columns", [Ember.computed], Object.getOwnPropertyDescriptor(_class.prototype, "columns"), _class.prototype), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "rows", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "persistNewEntry", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "deleteCashbookEntry", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteCashbookEntry"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "rowClicked", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "rowClicked"), _class.prototype)), _class));
  _exports.default = CashbookController;
});