define("frontend/admin/users/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K97vRKMY",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"ui table\"],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[1,[28,\"t\",[\"admin.users.email\"],null],false],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"\\n          \"],[1,[23,1,[\"email\"]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"td\",true],[10,\"class\",\"right aligned\"],[8],[0,\"\\n          \"],[5,\"link-to\",[[12,\"class\",\"ui icon button\"]],[[\"@route\",\"@model\"],[\"admin.user\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n            \"],[7,\"i\",true],[10,\"class\",\"pencil icon\"],[8],[9],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/admin/users/template.hbs"
    }
  });

  _exports.default = _default;
});