define("frontend/translations/cs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Add": "Přidat",
    "Cancel": "Zrušit",
    "Confirmation": "Potvrzení",
    "DataGrid": {
      "fulltextLabel": "Filtrace",
      "fulltextPlaceholder": "Hledaná fráze"
    },
    "Delete": "Smazat",
    "Deleted": "Smazáno",
    "Edit": "Upravit",
    "Identification": "Uživatelské jméno",
    "Login": "Přihlásit",
    "My secret password": "Moje tajné heslo",
    "Next": "Další",
    "No data": "Žádná data",
    "Password": "Heslo",
    "Previous": "Předchozí",
    "Save": "Uložit",
    "Saved": "Uloženo",
    "Saving": "Ukládám...",
    "Submit": "Odeslat",
    "admin": {
      "companies": {
        "address": "Adresa",
        "companyHeading": "Moje firma",
        "entitiesHeading": "Ekonomické entity",
        "expiryDate": "Zaplaceno do",
        "facebook": "Facebook",
        "isVatPayer": "Plátce DPH",
        "messageLength": "Délka zprávy",
        "phone": "Telefon",
        "save": "Uložit",
        "website": "Web"
      },
      "customer": {
        "visitsSum": "Celkem"
      },
      "employee": {
        "back": "Seznam zaměstnanců",
        "edit": "Editace",
        "heading": "Zaměstnanec {name}",
        "items": {
          "count": "Prodáno kusů",
          "itemName": "Položka",
          "itemSellingPrice": "Jednotková cena",
          "sum": "Suma",
          "sumOfPurchasePrices": "Suma v poslední nákupní ceně",
          "sumOfPurchasePricesVAT": "Suma v poslední nákupní ceně s DPH",
          "sumOfSellingPricesVAT": "Suma v prodejní ceně s DPH"
        },
        "itemsList": "Položky skladu",
        "month": "Měsíc",
        "percent": "%",
        "save": "Uložit",
        "savedError": "Při ukládání došlo k chybě",
        "savedOk": "Zaměstnanec uložen",
        "visits": "Zakázky",
        "visitsEmployeeShareTitle": "{shareOfSales}*{amountSales} + {shareOfServices}*{amountServices}"
      },
      "import": {
        "customer": {
          "csv": "Vstupní CSV",
          "example": "Bryxí&Tab;Michal&Tab;720160425\nDoe&Tab;&Tab;123456789",
          "exampleHeading": "Příklad CSV",
          "parse": "Parsovat",
          "save": "Uložit"
        },
        "heading": {
          "customer": "Zákazníci",
          "item": "Položky skladu",
          "page": "Import"
        },
        "item": {
          "csv": "Vstupní CSV",
          "example": "Inoa 3.14&Tab;5671237890&Tab;12.34 Kč\nDětský střih&Tab;01001010101&Tab;501,90\nŠampon&Tab;55555555555&Tab;69 CZK",
          "exampleHeading": "Příklad CSV",
          "parse": "Parsovat",
          "save": "Uložit"
        }
      },
      "item": {
        "back": "Seznam položek skladu",
        "edit": "Editace",
        "heading": "Položka skladu {name}",
        "mailtoBody": "Děkujeme za nákup {name}",
        "mailtoLabel": "Poslat e-mail všem kupujícím",
        "mailtoSubject": "Newsletter pro {name}",
        "savedError": "Položku se nepodařilo uložit",
        "savedOk": "Položka uložena",
        "sells": "Prodeje",
        "supplies": "Naskladnění"
      },
      "items": {
        "activeAndInactive": "Obojí",
        "barcode": "Čárový kód",
        "entityName": "Ekonomická entita",
        "export": "Exportovat",
        "isActiveLabel": "Zobrazit pouze",
        "name": "Název",
        "onlyActive": "Aktivní",
        "onlyInactive": "Neaktivní",
        "perPage": "Zobrazit položek na stránku",
        "purchasePrice": "Poslední nákupní cena",
        "sellingPrice": "Prodejní cena",
        "stock": "Skladem",
        "stockExportFileName": "sklad.xlsx",
        "stockExportSheetName": "Položky skladu",
        "sum": "Suma skladu",
        "sumExplained": "Počítají se pouze vyfiltrované položky, které nemají neomezené množství a mají poslední nákupní cenu > 0",
        "sumFiltered": "Vyfiltrováno"
      },
      "supply": {
        "lastSupplies": "Posledních {count} naskladnění",
        "newSupply": "Nové naskladnění"
      },
      "users": {
        "email": "E-mail"
      },
      "visit": {
        "createdAt": "Datum",
        "customer": "Zákazník",
        "delete": "Smazat",
        "employee": "Zaměstnanec",
        "header": "Zakázka",
        "note": "Poznámka",
        "reopenFailed": "Otevření selhalo",
        "reopenWarning": "Pozor! Již odeslané hlášení na EET se nebudou znovu odesílat. Všechny ostatní změny které provedete budou mít vliv napříč celým systémem (provize zaměstnanců, přehled tržeb, pokladní kniha, stav skladu).",
        "reopened": "Zakázka znovu otevřena",
        "revenue": {
          "amount": "Celkem",
          "amountSales": "Prodeje",
          "amountServices": "Služby",
          "entityName": "Ekonomická entita"
        },
        "revenues": "Příjmy",
        "saveFailed": "Uložení selhalo",
        "saved": "Uloženo",
        "sells": "Položky",
        "view": "Zobrazit",
        "visitDetails": "Detaily"
      }
    },
    "api": {
      "200": "Uloženo",
      "500": "Nastala chyba: {message}"
    },
    "app-menu": {
      "top": {
        "logoAlt": "beauty-pay.cz logo"
      }
    },
    "application": {
      "title": "kasa"
    },
    "calendar": {
      "allEmployees": "Všichni zaměstnanci",
      "clipboard": "Schránka",
      "copySchedules": "Kopírovat směny",
      "day": "Dne",
      "editMode": "Editovat směny",
      "end": "Konec",
      "from": "od",
      "header": "Rezervace",
      "modeReservation": "ne",
      "modeSchedule": "ano",
      "pasteSchedules": "Vložit směny",
      "reservationActions": "Akce",
      "start": "Začátek",
      "to": "do",
      "weeklySchedules": "Zobrazený týden"
    },
    "cashbook": {
      "add": "Přidat",
      "amount": "Částka",
      "createdAt": "Datum",
      "disclaimer": "Výběr z pokladní knihy dělejte přes zápornou částku.",
      "heading": "Pokladní kniha",
      "isVisit": "Zakázka",
      "kind": "Typ / Akce",
      "note": "Poznámka"
    },
    "createdAt": "Vytvořeno",
    "currency": "Kč",
    "customer": {
      "action": "Akce",
      "add": "Přidat zákazníka",
      "allCustomers": "Seznam zákazníků",
      "all_visits": "Seznam návštěv",
      "birth": "Datum narození",
      "birthdayIn": "Narozeniny",
      "export": "Exportovat kontakty",
      "firstName": "Jméno",
      "fullName": "Jméno",
      "gender": "Pohlaví",
      "genders": {
        "female": "Žena",
        "male": "Muž"
      },
      "isApproved": "Schválen",
      "lastName": "Příjmení",
      "lastVisitDate": "Poslední návštěva",
      "mail": "E-mail",
      "note": "Poznámka",
      "phone": "Telefon",
      "saveErrro": "Chyba při uložení",
      "saveOk": "Uloženo",
      "visits_length": "Návštěv"
    },
    "customers": {
      "all": "Všichni zákazníci",
      "filterBy": {
        "label": "Filtr",
        "placeholder": "Jan Novák..."
      },
      "newCustomer": "Nový zákazník",
      "newsletter": "Newsletter"
    },
    "dateSelect": {
      "date": "den",
      "enabled": "Poskytnout",
      "month": "měšíc",
      "year": "rok"
    },
    "employee": {
      "action": "Akce",
      "add": "Přidat",
      "all_employees": "Všichni zaměstnanci",
      "all_visits": "Všechny zakázky",
      "color": "Barva",
      "firstName": "Jméno",
      "isActive": "Aktivní",
      "lastName": "Příjmení",
      "photoUrl": "Fotografie"
    },
    "employees": {
      "all": "Zaměstnanci"
    },
    "failed": "selhalo",
    "female": "Žena",
    "form": {
      "save": "Uložit"
    },
    "formFor": {
      "amount": "Částka",
      "cashbook-entry": {
        "amount": "Částka",
        "note": "Poznámka"
      },
      "company": {
        "address": "Adresa",
        "email": "E-mail",
        "facebook": "Facebook",
        "invoice-header": "Hlavička na účtence",
        "invoice-logo": "Logo na účtence",
        "is-invoice-printing-active": "Tisknout účtenky",
        "name": "Název firmy",
        "notificationPlaceholdersHelp": "Delší zprávy jak 170 znaků budou rozděleny na více SMS. Zástupné symboly: %\\{company_name\\} - jméno firmy, %\\{date\\} - datum návštěvy, %\\{time\\} čas návštěvy, %\\{company_phone\\} - telefon firmy",
        "notificationWarning": "Před zapnutím nejdříve prosím kontaktujte podporu beauty-pay.cz",
        "phone": "Telefon",
        "reservation-notification-template": "Šablona SMS zprávy",
        "save": "Uložit",
        "website": "Web",
        "will-notify-reservations": "SMS upozornění zákazníků na nadcházející rezervace"
      },
      "customer": {
        "agree": "Souhlasím s",
        "birth": "Datum narození",
        "first-name": "Jméno",
        "gender": "Pohlaví",
        "is-approved": "Schválen",
        "last-name": "Příjmení",
        "mail": "E-mail",
        "note": "Poznámka",
        "phone": "Telefon",
        "terms-and-conditions": "zpracování osobních údajů"
      },
      "employee": {
        "color": "Barva",
        "first-name": "Jméno",
        "is-active": "Aktivní",
        "last-name": "Příjmení",
        "photo-url": "URL fotky",
        "share-of-sales": "Podíl z prodejů",
        "share-of-sales-percent": "Podíl z prodejů",
        "share-of-services": "Podíl ze služeb",
        "share-of-services-percent": "Podíl ze služeb"
      },
      "entity": {
        "business-register": "Zápis v obchodním rejstříku",
        "headquarters": "Sídlo společnosti",
        "identification-number": "IČO",
        "invoice-header": "Patička na účtence",
        "is-vat-payer": "Plátce DPH",
        "name": "Název entity",
        "premisesid": "Číslo provozovny",
        "registerid": "Číslo pokladny",
        "send-eet": "Odesílat EET - pouze prodeje",
        "vatid": "DIČ"
      },
      "identification": "E-mail",
      "item": {
        "barcode": "Čárový kód",
        "entity": "Ekonomická entita",
        "is-active": "Aktivní",
        "is-service": "Služba",
        "name": "Název",
        "selling-price": "Prodejní cena",
        "unlimited": "Neomezeně",
        "warning-threshold": "Minimálně skladem"
      },
      "note": "Poznámka",
      "password": "Heslo",
      "reservation": {
        "end": "do",
        "note": "Poznámka",
        "start": "od",
        "will-notify": "Upozornit zákazníka dopředu SMS zprávou"
      },
      "schedule": {
        "note": "Poznámka"
      },
      "sell": {
        "count": "Kusů",
        "discount": "Sleva",
        "price": "Cena"
      },
      "supply": {
        "item": {
          "selling-price": "Prodejní cena"
        },
        "itemName": "Položka skladu",
        "purchase-price": "Nákupní cena (bez DPH)",
        "quantity": "Množství",
        "vat": "DPH nákupu"
      },
      "user": {
        "password": "Heslo",
        "passwordConfirmation": "Potvrzení hesla"
      },
      "username": "E-mail"
    },
    "intervalPicker": {
      "export": "Stáhnout",
      "from": "od",
      "invalidRange": "Datum \"od\" nesmí být po datumu \"do\".",
      "minusMonth": "-1 měsíc",
      "plusMonth": "+1 měsíc",
      "thisDay": "Dnes",
      "thisMonth": "Tento měsíc",
      "thisWeek": "Tento týden",
      "to": "do"
    },
    "item": {
      "action": "Akce",
      "actualCount": "Skladem",
      "barcode": "Čárový kód",
      "bought": "Naskladněno",
      "edit": "Upravit",
      "isActive": "Aktivní",
      "isService": "Služba",
      "margin": "Marže",
      "name": "Název",
      "new": "Nová položka skladu",
      "newItemEntity": "Ekonomický subjekt",
      "purchasePrice": "Nákup",
      "sellingPrice": "Prodejní cena",
      "sold": "Prodáno",
      "stock": "Skladem",
      "unlimited": "Neomezené množství",
      "unlimited_or_stock": "Skladem",
      "warningThreshold": "Minimálně skladem"
    },
    "items": {
      "active": "Aktivní",
      "add": "Přidat",
      "all": "Sklad",
      "filterBy": {
        "count": "kusů",
        "label": "Filtrace",
        "placeholder": "Šampón...",
        "showing": "Zobrazeno"
      },
      "inactive": "Neaktivní",
      "newBarcode": "123456789",
      "newName": "Nová položka",
      "newSellingPrice": "100",
      "newStock": "10",
      "newWarningThreshold": "5"
    },
    "john@doe": {
      "cz": "jan@novak.cz"
    },
    "loadMore": "Více...",
    "loading": "... načítám ...",
    "login": {
      "branding": "beauty-pay.cz",
      "header": "Přihlášení",
      "invalidCredentials": "Neplatné přihlašovací údaje",
      "logoAlt": "beauty-pay.cz logo",
      "submit": "Přihlásit"
    },
    "male": "Muž",
    "models": {
      "cashbookEntry": {
        "amount": "Částka",
        "isVisit": "Zakázka",
        "kind": "Typ",
        "note": "Poznámka",
        "touchedAt": "Datum"
      },
      "company": {
        "_modelName": "{count, plural, =1 {Firma} =2 {Firmy} other {Firem}}",
        "address": "Adresa",
        "email": "E-mail",
        "expiryDate": "Datum vypršení",
        "facebook": "Facebook URL",
        "facebookTitle": "Jméno na Facebooku",
        "instagram": "Instagram URL",
        "instagramTitle": "Jméno na Instagramu",
        "invoiceHeader": "Hlavička na účtence",
        "invoiceLogo": "Logo",
        "isInvoicePrintingActive": "Tisknout účtenku",
        "name": "Název",
        "note": "Poznámka",
        "phone": "Telefon",
        "phoneLandline": "Pevná linka",
        "reservationNotificationTemplate": "Šablona upozornění",
        "website": "Web",
        "willNotifyReservations": "Odesílat upozornění o rezervacích"
      },
      "customer": {
        "_modelName": "Zákazník",
        "birth": "Datum narození",
        "firstName": "Jméno",
        "gender": "Pohlaví",
        "genders": {
          "female": "Žena",
          "male": "Muž"
        },
        "isApproved": "Schválený",
        "lastName": "Příjmení",
        "lastVisitDate": "Poslední návštěva",
        "mail": "E-mail",
        "note": "Poznámka",
        "phone": "Telefonní číslo",
        "termsConfirmation": "Souhlasím s podmínkami",
        "visitsCount": "Návštěv"
      },
      "employee": {
        "_modelName": "Zaměstnanec"
      },
      "entity": {
        "_modelName": "{count, plural, =1 {Ekonomická entita} =2 {Ekonomické entity} other {Ekonomických entit}}",
        "businessRegister": "Zápis v obchodním rejstříku",
        "headquarters": "Sídlo společnosti",
        "identificationNumber": "IČO",
        "invoiceHeader": "Patička na účtence",
        "isVatPayer": "Plátce DPH",
        "name": "Název entity",
        "premisesid": "Číslo provozovny",
        "registerid": "Číslo pokladny",
        "sendEet": "Odesílat EET - pouze prodeje",
        "vatid": "DIČ"
      },
      "invoice": {
        "_modelName": "{count, plural, =1 {Faktura} =2 {Faktury} other {Faktur}}",
        "amount": "Částka",
        "end": "Do",
        "isPaid": "Zaplaceno",
        "note": "Poznámka",
        "sentSms": "SMS",
        "start": "Od"
      },
      "item": {
        "_modelName": "{count, plural, =1 {Položka skladu} =2 {Položky skladu} other {Položek skladu}}",
        "barcode": "Čárový kód",
        "bought": "Naskladněno",
        "isActive": "Aktivní",
        "isService": "Služba",
        "name": "Název",
        "sellingPrice": "Prodejní cena",
        "sold": "Prodáno",
        "stock": "Skladem",
        "unlimited": "Neomezené množství",
        "warningThreshold": "Minimálně skladem"
      },
      "notification": {
        "_modelName": "Notifikace",
        "message": "Text",
        "notification": "{count, plural, =1 {Notifikace} =2 {Notifikace} other {Notifikací}}",
        "phone": "Telefon",
        "sendAt": "Odesláno",
        "sentSms": "Zpráv",
        "status": "Stav",
        "statusMessage": "Status"
      },
      "payment": {
        "_modelName": "{count, plural, =1 {Platba} =2 {Platby} other {Plateb}}",
        "amount": "Částka",
        "end": "Do",
        "note": "Poznámka",
        "start": "Od"
      },
      "reservation": {
        "_modelName": "{count, plural, other {Rezervace}}",
        "end": "Konec",
        "note": "Poznámka",
        "start": "Začátek",
        "willNotify": "Upozornit"
      },
      "sell": {
        "_modelName": "{count, plural, =1 {Zakázka} =2 {Zakázky} other {Zakázek}}",
        "count": "Kusů",
        "discount": "Sleva",
        "item": "Položka",
        "price": "Jed. cena",
        "sum": "Suma"
      },
      "supply": {
        "_modelName": "{count, plural, =1 {Naskladnění} =2 {Naskladnění} other {Naskladnění}}",
        "createdAt": "Vytvořeno",
        "margin": "Marže",
        "purchasePrice": "Nákupní cena (bez DPH)",
        "quantity": "Množství",
        "vat": "DPH nákupu"
      },
      "user": {
        "_modelName": "{count, plural, =1 {Uživatel} =2 {Uživatelé} other {Uživatelů}}",
        "email": "E-mail",
        "password": "Heslo"
      },
      "visit": {
        "_modelName": "{count, plural, =1 {Návštěva} =2 {Návštěvy} other {Návštěv}}",
        "completed": "Uzavřeno",
        "note": "Poznámka",
        "price": "Cena"
      }
    },
    "noNewNotifications": "Žádné nové notifikace",
    "not_needed": "nebylo třeba",
    "notification": {
      "message": "Zpráva",
      "phone": "Telefon",
      "sendAt": "Čas odeslání",
      "status": "Stav"
    },
    "overview": {
      "EET": "EET",
      "amount": "Celkem",
      "amountSales": "Prodeje",
      "amountServices": "Služby",
      "date": "Datum",
      "eetDetails": "Detaily EET",
      "eetIsNotOk": "Pro vybrané období EET vykazuje chyby odeslání.",
      "eetIsOk": "Pro vybrané období je EET v pořádku.",
      "eetState": "EET",
      "entityName": "Název entity",
      "export": "Exportovat",
      "exportFileName": "zakazky.xlsx",
      "exportSheetName": "Zakázky",
      "heading": "Přehled zakázek",
      "isPagingExceeded": "Ve vybraném období je více než {max} zakázek. Součet nelze vypočítat. Prosím, zadejte kratší období.",
      "paidByCard": "Kartou",
      "paidInCash": "Hotově",
      "price": "Cena za položky",
      "priceWithTip": "Celková částka i s dýškem",
      "state": {
        "failed": "Odeslání EET selhalo",
        "not_needed": "EET nebylo třeba odeslat",
        "sent": "EET bylo úspěšně odesláno"
      },
      "sums": "Součty",
      "thisMonth": "tento měsíc",
      "thisWeek": "tento týden",
      "tip": "Dýško",
      "today": "dnes",
      "typeOfPayment": "Typ platby",
      "visits": "Zakázky"
    },
    "pieces": "ks",
    "registration": {
      "failure": "Při registraci došlo k chybě",
      "heading": "Registrace",
      "save": "Uložit",
      "success": "Registrace proběhla úspěšně"
    },
    "reservation": {
      "createVisit": "Vytvořit zakázku",
      "customer": "Zákazník",
      "employee": "Zaměstnanec",
      "invalidNotification": "Není vyplněný telefon, prosím zrušte volbu zaslání SMS",
      "modalHeader": "Rezervace",
      "note": "Poznámka",
      "removeReservation": "Odstranit rezervaci",
      "saveReservation": "Uložit rezervaci",
      "selectCustomer": "Vyberte zákazníka",
      "selectEmployee": "Vyberte zaměstnance",
      "selectedEmployee": "Zaměstnanec"
    },
    "revenues": {
      "createdAt": "Datum",
      "eetResponse": "Odezva EET serverů",
      "eetState": "Stav",
      "heading": "Příjmy pro které je stav EET: {state}",
      "resendEet": "Odeslat EET"
    },
    "schedule": {
      "employee": "Zaměstnanec",
      "modalHeader": "Směna",
      "removeSchedule": "Odstranit směnu",
      "saveSchedule": "Uložit směnu",
      "selectEmployee": "Vyberte zaměstnance"
    },
    "sell": {
      "action": "Akce",
      "count": "Kusů",
      "customer": "Zákazník",
      "date": "Datum",
      "employee": "Zeměstnanec",
      "item": "Položka",
      "itemType": "Typ",
      "select_item": "-- Vyber položku --",
      "sum_price": "Suma",
      "unit_price": "Jed. cena",
      "visit": "Zakázka"
    },
    "sent": "odesláno",
    "sidebar": {
      "admin": {
        "customers": "Zákazníci",
        "employees": "Zaměstnanci",
        "items": "Sklad",
        "notifications": "Upozornění",
        "preferences": "Firma",
        "supply": "Naskladnění",
        "users": "Uživatelé"
      },
      "calendar": "Rezervace",
      "cashbook": "Pokladní kniha",
      "changelog": "Informace",
      "customers": "Zákazníci",
      "forceReload": "Znovu načíst aplikaci",
      "heading": {
        "admin": "Administrátor",
        "employee": "Zaměstnanec",
        "navigation": "Navigace",
        "profile": "Profil"
      },
      "items": "Sklad",
      "logout": "Odhlásit",
      "overview": "Zakázky",
      "reminders": "Upozornění",
      "visits": "Otevřené zakázky"
    },
    "supply": {
      "create": "Naskladnit",
      "created_at": "Naskladněno",
      "heading": "Naskladnění",
      "item": "Položka skladu",
      "lastPurchasePrice": "Poslední nákupní cena",
      "purchasePrice": "Jednotková cena",
      "quantity": "Množství",
      "sum": "Suma",
      "vat": "DPH"
    },
    "test": "passsed",
    "title": {
      "branding": "beauty-pay.cz"
    },
    "validations": {
      "between": "Musí mít mezi {min} a {max} znaky",
      "email": "Nplatná e-mailová adresa",
      "inclusion": "Musí být vybráno",
      "invalid": "Neplatný formát",
      "present": "Prosím vyplňte"
    },
    "vat": {
      "no": "(bez DPH)",
      "yes": "(s DPH)"
    },
    "versionChecker": {
      "changelog": "change logu",
      "reload": "obnovit",
      "textChangelog": "Seznam změn je k dispozici v",
      "textReload": "Aplikace byla aktualizována. Prosím, dokončete svou práci a poté klikněte na"
    },
    "visit": {
      "all_open": "Všechny otevřené zakázky",
      "all_visits": "Všechny otevřené zakázky",
      "amountSales": "Prodeje",
      "amountServices": "Služby",
      "auto_complete": "Hledej...",
      "back_to_customer": "Zpátky na zákazníka",
      "back_to_visit": "Zpátky do zakázky",
      "checkout": "K pokladně",
      "close": "Uzavřít",
      "close_warning": "Zakázka bude uzavřena. Již nebude možno přidávat, odebírat ani měnit položky.",
      "copyDiscountsPopup": "Zkopírovat hodnotu z prvního řádku do všech řádků.",
      "count": "Počet",
      "createdAt": "Datum",
      "creatingVisitFailed": "Nepodařilo se vytvořit zakázku, zkuste to později",
      "creatingVisitSucceeded": "Zakázka vytvořena",
      "customer": "Zákazník",
      "deleteVisit": "Smazat prázdnou zakázku",
      "element": "Položka",
      "employee": "Zaměstnanec",
      "employeeShareSales": "Podíl zaměstnance prodej",
      "employeeShareServices": "Podíl zaměstnance služby",
      "employee_margin": "Podíl zaměstnance",
      "enablePaymentByCard": "platba kartou",
      "hasUnsavedSells": "Neuložili jste položku zakázky. Klikněte na zelené plus, nebo položku smažte.",
      "isInvoicePrintingActive": "Tisknout účtenku",
      "itemName": "Položka",
      "new": {
        "confirm": "Přidat",
        "selectCustomer": "-- Vyber --",
        "selectEmployee": "-- Vyber --"
      },
      "noLastVisit": "---",
      "note": "Poznámka k zakázce",
      "noteUpdated": "Poznámka uložena",
      "number": "Hodnota",
      "paidByCard": "Zaplaceno kartou",
      "paid_in_cash": "Zaplaceno v hotovosti",
      "paymentSection": "Platba",
      "price": "Celková cena",
      "priceSection": "Částka",
      "print": {
        "bkp": "bkp",
        "count": "Množství",
        "eetAmount": "částka na EET",
        "facebook": "Facebook",
        "fik": "fik",
        "footer": "vytvořeno pomocí systému www.beauty-pay.cz",
        "goToVisits": "Zpátky na seznam zakázek",
        "identificationNumber": "IČO",
        "isNotVatPayer": "není plátcem DPH",
        "isVatPayer": "plátce DPH (21%)",
        "name": "Položka",
        "or": "nebo",
        "phone": "tel.",
        "premisesid": "Číslo provozovny",
        "price": "Celkem",
        "print": "Vytisknout",
        "registerid": "Číslo pokladny",
        "revenueId": "Číslo účtenky",
        "sum": "Cena celkem:",
        "typeOfEet": "Režim tržby: běžný",
        "unitPrice": "JC",
        "vatid": "DIČ"
      },
      "receivedCash": "Přijato v hotovosti",
      "reopen": "Znovu otevřít",
      "return_amount": "K vrácení",
      "selectAnItem": "Vyberte položku",
      "sellPrice": "Jednotková cena",
      "sellSum": "Cena celkem",
      "sum_price_is": "Celková částka",
      "sum_with_tip_is": "Celková částka i s dýškem",
      "tip": "Dýško",
      "updatedAt": "Datum",
      "visit_of": "Návštěva"
    },
    "visits": {
      "new": "Nová zakázka "
    },
    "will_send": "k odeslání",
    "x-customer": {
      "birth": "Datum narození",
      "mail": "E-mail",
      "phone": "Telefon",
      "save": "Uložit"
    },
    "x-note": {
      "noNote": "[poznámka zákazníka]",
      "note": "poznámka zákazníka",
      "save": "Uložit"
    }
  };
  _exports.default = _default;
});