define("frontend/components/x-customer/visits/visit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QPhE/2Tc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"\\n  \"],[7,\"img\",true],[11,\"src\",[23,0,[\"visit\",\"employee\",\"photoUrl\"]]],[10,\"class\",\"ui image avatar\"],[11,\"alt\",[23,0,[\"visit\",\"employee\",\"fullName\"]]],[8],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"visit\",\"note\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"sticky note icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"span\",true],[8],[0,\"\\n    \"],[5,\"x-date/medium\",[],[[\"@value\"],[[23,0,[\"visit\",\"createdAt\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[8],[0,\"\\n    \"],[5,\"money\",[],[[\"@value\"],[[23,0,[\"visit\",\"price\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"visit\",\"note\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[0,\"\\n      \"],[1,[23,0,[\"visit\",\"note\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[5,\"x-customer/visits/visit/sells\",[],[[\"@sells\"],[[23,0,[\"visit\",\"sells\"]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/components/x-customer/visits/visit/template.hbs"
    }
  });

  _exports.default = _default;
});