define("frontend/calendar/employee/route", ["exports", "frontend/routes/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  var EmployeeRoute = (_class = (_temp = class EmployeeRoute extends _protected.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "employees", _descriptor, this);

      _initializerDefineProperty(this, "customers", _descriptor2, this);
    }

    model(params) {
      var calendarParams = this.paramsFor("calendar");
      var filter = {
        from: calendarParams.from,
        to: calendarParams.to
      };

      if (params.employee_id !== "all") {
        filter.employeeId = params.employee_id;
      }

      var reservations = this.store.query("reservation", {
        include: "customer,employee",
        filter
      });
      var schedules = this.store.query("schedule", {
        include: "employee",
        filter
      });
      return Ember.RSVP.hash({
        date: calendarParams.from,
        viewName: calendarParams.viewName,
        customers: this.customers,
        employees: this.employees,
        reservations,
        schedules,
        employeeId: params.employee_id
      });
    }

    refreshCalendar() {
      this.refresh();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "employees", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "customers", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "refreshCalendar", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "refreshCalendar"), _class.prototype)), _class);
  _exports.default = EmployeeRoute;
});