define("frontend/authenticators/devise", ["exports", "ember-simple-auth/authenticators/devise"], function (_exports, _devise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _Devise extends _devise.default {}

  _exports.default = _Devise;
});