define("frontend/preference/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  class PendingResponse extends Ember.ArrayProxy.extend(Ember.PromiseProxyMixin) {}

  var PreferenceService = (_dec = Ember.computed("companies.[]"), _dec2 = Ember.computed.mapBy("company.entities", "name"), _dec3 = Ember.computed.mapBy("company.entities", "identificationNumber"), _dec4 = Ember.computed.mapBy("company.entities", "headquarters"), (_class = (_temp = class PreferenceService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "entitiesNames", _descriptor2, this);

      _initializerDefineProperty(this, "entitiesIdentificationNumbers", _descriptor3, this);

      _initializerDefineProperty(this, "entitiesHeadquarters", _descriptor4, this);
    }

    get companies() {
      var query = {
        include: "entities"
      };
      var promise = this.store.query("company", query);
      var responseObject = PendingResponse.create({
        promise
      });
      responseObject.catch(() => {});
      return responseObject;
    }

    get company() {
      return this.companies.firstObject;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "companies", [Ember.computed], Object.getOwnPropertyDescriptor(_class.prototype, "companies"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "company", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "company"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "entitiesNames", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "entitiesIdentificationNumbers", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "entitiesHeadquarters", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PreferenceService;
});