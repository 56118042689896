define("frontend/models/revenue", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    amount: _emberData.default.attr("number"),
    amountServices: _emberData.default.attr("number", {
      readOnly: true
    }),
    amountSales: _emberData.default.attr("number", {
      readOnly: true
    }),
    eetBkp: _emberData.default.attr("string"),
    eetFik: _emberData.default.attr("string"),
    eetResponse: _emberData.default.attr("object"),
    eetState: _emberData.default.attr("string", {
      defaultValue: "not_needed"
    }),
    createdAt: _emberData.default.attr("date"),
    visit: _emberData.default.belongsTo("visit", {
      async: true
    }),
    entity: _emberData.default.belongsTo("entity", {
      async: true
    })
  });

  _exports.default = _default;
});