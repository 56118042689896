define("frontend/admin/items/sum/route", ["exports", "frontend/routes/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class SumRoute extends _protected.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", {
        isActive: {
          refreshModel: true
        },
        fulltext: {
          refreshModel: true
        }
      });
    }

    model(params) {
      var query = {
        filter: {
          fulltext: params.fulltext,
          isActive: params.isActive
        },
        include: "last-supply,entity"
      };
      return Ember.RSVP.hash({
        items: this.store.query("item", query),
        entities: this.store.findAll("entity")
      });
    }

  }

  _exports.default = SumRoute;
});