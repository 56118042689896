define("frontend/components/x-time-field/component", ["exports", "@ember-decorators/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var XTimeField = (_dec = (0, _component.classNames)("inline", "fields"), _dec2 = Ember.computed("value"), _dec3 = Ember.computed("internalValue"), _dec4 = Ember.computed("internalValue"), _dec(_class = (_class2 = class XTimeField extends Ember.Component {
    get hourOptions() {
      return Array.from({
        length: 24
      }, (x, i) => i.toString());
    }

    get minuteOptions() {
      return Array.from({
        length: 4
      }, (x, i) => (i * 15).toString());
    }

    get internalValue() {
      return this.value;
    }

    set internalValue(newValue) {
      this.onchange(newValue.toDate());
      return;
    }

    get valueHours() {
      return (0, _moment.default)(this.internalValue).hours().toString();
    }

    set valueHours(newValue) {
      var updatedHours = (0, _moment.default)(this.internalValue).hour(newValue);
      this.set("internalValue", updatedHours);
      return;
    }

    get valueMinutes() {
      return (0, _moment.default)(this.internalValue).minutes().toString();
    }

    set valueMinutes(newValue) {
      var updatedMinutes = (0, _moment.default)(this.internalValue).minute(newValue);
      this.set("internalValue", updatedMinutes);
      return;
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "hourOptions", [Ember.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "hourOptions"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "minuteOptions", [Ember.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "minuteOptions"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "internalValue", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "internalValue"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "valueHours", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "valueHours"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "valueMinutes", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "valueMinutes"), _class2.prototype)), _class2)) || _class);
  _exports.default = XTimeField;
});