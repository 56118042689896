define("frontend/changelog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nV9bMN1s",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"changelog\"],[8],[0,\"\\n  \"],[1,[28,\"markdown-to-html\",[[23,0,[\"model\"]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"hr\",true],[8],[9],[0,\"\\n\"],[1,[23,0,[\"environment\"]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/changelog/template.hbs"
    }
  });

  _exports.default = _default;
});