define("frontend/models/supply", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    purchasePrice: _emberData.default.attr("number"),
    quantity: _emberData.default.attr("number"),
    vat: _emberData.default.attr("number"),
    createdAt: _emberData.default.attr(),
    updatedAt: _emberData.default.attr(),
    item: _emberData.default.belongsTo("item"),
    lastSupplyFor: _emberData.default.hasMany("item", {
      async: true,
      inverse: "lastSupply"
    }),
    sum: Ember.computed("quantity", "purchasePrice", function () {
      return this.quantity * this.purchasePrice;
    }),
    sumWithVat: Ember.computed("vat", "sum", function () {
      return this.sum * (1 + this.vat / 100);
    })
  });

  _exports.default = _default;
});