define("frontend/components/x-customer/secret-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oQb3NWsv",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[4,\"if\",[[23,0,[\"isFieldEmpty\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"formVisible\"]]],null,{\"statements\":[[4,\"form-for\",[[23,0,[\"customer\"]]],[[\"submit\",\"class\"],[[28,\"action\",[[23,0,[]],\"save\"],null],\"ui form\"]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isBirth\"]]],null,{\"statements\":[[0,\"          \"],[7,\"label\",true],[8],[1,[28,\"t\",[\"x-customer.birth\"],null],false],[9],[0,\"\\n          \"],[5,\"date-select\",[],[[\"@selected\",\"@onSelect\"],[[23,0,[\"tempValue\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"tempValue\"]]],null]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[5,\"input\",[[12,\"placeholder\",[28,\"t\",[[23,0,[\"placeholder\"]]],null]]],[[\"@type\",\"@value\"],[\"text\",[23,0,[\"tempValue\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\\n      \"],[6,[23,1,[\"submit\"]],[],[[\"@class\"],[\"ui primary button\"]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"i\",false],[12,\"class\",\"large icons\"],[12,\"role\",\"button\"],[3,\"on\",[\"click\",[23,0,[\"toggleForm\"]]]],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"big grey dont icon\"],[8],[9],[0,\"\\n      \"],[7,\"i\",true],[11,\"class\",[29,[\"black \",[23,0,[\"icon\"]],\" icon\"]]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/components/x-customer/secret-form/template.hbs"
    }
  });

  _exports.default = _default;
});