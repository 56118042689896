define("frontend/admin/users/route", ["exports", "frontend/routes/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UsersRoute extends _protected.default {
    model() {
      return this.store.findAll("user");
    }

  }

  _exports.default = UsersRoute;
});