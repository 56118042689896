define("frontend/admin/customer/visits/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "51A79aBT",
    "block": "{\"symbols\":[],\"statements\":[[5,\"data-grid\",[],[[\"@columns\",\"@rows\",\"@sort\",\"@fillColumnIndex\",\"@onUpdateSort\",\"@onRowClick\"],[[23,0,[\"columns\"]],[23,0,[\"rows\"]],[23,0,[\"sort\"]],2,[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"sort\"]]],null]],null],[28,\"action\",[[23,0,[]],\"rowClicked\"],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/admin/customer/visits/template.hbs"
    }
  });

  _exports.default = _default;
});