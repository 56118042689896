define("frontend/calendar/index/route", ["exports", "frontend/routes/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IndexRoute extends _protected.default {
    beforeModel() {
      this.transitionTo("calendar.employee", "all");
    }

  }

  _exports.default = IndexRoute;
});