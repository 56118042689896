define("frontend/components/app-menu/top/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    lastMessage: Ember.computed("flashMessages.queue.[]", function () {
      return this.get("flashMessages.queue").get("lastObject");
    }),
    lastMessageColor: Ember.computed("lastMessage.type", function () {
      var lastMessageType = this.get("lastMessage.type");

      switch (lastMessageType) {
        case "success":
          return "green";

        case "danger":
          return "red";

        default:
          return "";
      }
    }),
    actions: {
      logoutMeOut() {
        this.logout();
      },

      reload() {
        window.location.reload(true);
      }

    }
  });

  _exports.default = _default;
});