define("frontend/admin/item/route", ["exports", "frontend/routes/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

  function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

  class ItemRoute extends _protected.default {
    model(params) {
      var _this = this;

      return _asyncToGenerator(function* () {
        var item;

        if (params.id === "new") {
          var entities = yield _this.store.findAll("entity");
          item = _this.store.createRecord("item", {
            entity: entities.get("firstObject")
          });
        } else {
          item = _this.store.find("item", params.id);
        }

        return item;
      })();
    }

  }

  _exports.default = ItemRoute;
});