define("frontend/components/x-visit/last-visit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GDk7RbaB",
    "block": "{\"symbols\":[\"@value\"],\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[7,\"span\",true],[11,\"title\",[23,1,[]]],[11,\"class\",[29,[\"label \",[23,0,[\"class\"]]]]],[8],[1,[23,0,[\"human\"]],false],[9]],\"parameters\":[]},{\"statements\":[[1,[28,\"t\",[\"visit.noLastVisit\"],null],false]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/components/x-visit/last-visit/template.hbs"
    }
  });

  _exports.default = _default;
});