define("frontend/admin/employee/route", ["exports", "frontend/routes/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class EmployeeRoute extends _protected.default {
    model(params) {
      var employee;

      if (params.id === "new") {
        employee = this.store.createRecord("employee");
      } else {
        employee = this.store.find("employee", params.id);
      }

      return employee;
    }

  }

  _exports.default = EmployeeRoute;
});