define("frontend/calendar/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class CalendarController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", ["from", "to", "viewName"]);

      _defineProperty(this, "from", null);

      _defineProperty(this, "to", null);

      _defineProperty(this, "viewName", "agendaWeek");
    }

  }

  _exports.default = CalendarController;
});