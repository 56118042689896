define("frontend/router", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor() {
      super(...arguments);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route("login");
    this.route("items", function () {});
    this.route("customers", function () {});
    this.route("customer", function () {
      this.route("show", {
        path: "/:id"
      });
      this.route("new");
    });
    this.route("calendar", function () {
      this.route("employee", {
        path: "/:employee_id"
      });
    });
    this.route("visits", function () {});
    this.route("visit", {
      path: "/visit/:id"
    }, function () {
      this.route("show");
      this.route("checkout");
      this.route("print");
    });
    this.route("cashbook");
    this.route("admin", function () {
      this.route("notifications");
      this.route("revenues");
      this.route("import", function () {
        this.route("item");
        this.route("customer");
      });
      this.route("users");
      this.route("user", {
        path: "/user/:id"
      });
      this.route("overview");
      this.route("items", function () {
        this.route("stock");
        this.route("sum");
      });
      this.route("item", {
        path: "item/:id"
      }, function () {
        this.route("edit");
        this.route("sells");
        this.route("supplies");
      });
      this.route("customers");
      this.route("employees");
      this.route("visit", {
        path: "visit/:id"
      });
      this.route("customer", {
        path: "customer/:id"
      }, function () {
        this.route("edit");
        this.route("visits");
      });
      this.route("employee", {
        path: "employee/:id"
      }, function () {
        this.route("edit");
        this.route("visits", function () {
          this.route("year", {
            path: "/:year"
          }, function () {
            this.route("month", {
              path: "/:month"
            });
          });
        });
        this.route("items", function () {});
      });
      this.route("supply");
      this.route("preferences", function () {
        this.route("company");
        this.route("entities");
      });
    });
    this.route("registration", function () {
      this.route("terms");
      this.route("gdpr");
    });
    this.route("reservations");
    this.route("changelog");
  });
});