define("frontend/admin/item/sells/route", ["exports", "frontend/routes/protected", "ember-cli-pagination/remote/route-mixin"], function (_exports, _protected, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class SellsRoute extends _protected.default.extend(_routeMixin.default) {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", {
        sort: {
          refreshModel: true
        }
      });
    }

    model() {
      var item = this.modelFor("admin.item");
      var {
        id
      } = this.paramsFor("admin.item");
      var query = {
        include: "visit.employee,visit.customer",
        filter: {
          // eslint-disable-next-line camelcase
          item_id: id
        }
      };
      var sells = this.findPaged("sell", query);
      return Ember.RSVP.hash({
        item,
        sells
      });
    }

  }

  _exports.default = SellsRoute;
});