define("frontend/admin/preferences/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "we+h1D7w",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui top attached tabular menu\"],[8],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"admin.preferences.company\"]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"models.company._modelName\"],[[\"count\"],[1]]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"admin.preferences.entities\"]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"models.entity._modelName\"],[[\"count\"],[2]]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui bottom attached active tab segment\"],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/admin/preferences/template.hbs"
    }
  });

  _exports.default = _default;
});