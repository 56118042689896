define("frontend/admin/supply/controller", ["exports", "ember-concurrency", "bp-ember-components/validations/supply", "ember-changeset-validations", "ember-changeset"], function (_exports, _emberConcurrency, _supply, _emberChangesetValidations, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  var SupplyController = (_dec = Ember.computed.oneWay("model.items.active"), _dec2 = Ember.computed("model.supply"), _dec3 = Ember.computed("model.{supplies.[],supply.[]}"), _dec4 = (0, _emberConcurrency.task)(function* (supply) {
    var item = supply.get("item");
    yield supply.save().then(() => {
      this.flashMessages.positive(this.intl.t("Saved"));
      item.reload().then(item => {
        if (item.hasDirtyAttributes) {
          item.save().then(() => {
            this.flashMessages.positive(this.intl.t("Saved"));
          }, response => {
            this.flashMessages.negative(response);
          });
        }
      }, response => {
        this.flashMessages.negative(response);
      });
    }, response => {
      this.flashMessages.negative(response);
    }).finally(() => {
      this.send("refreshRoute");
    });
  }).drop(), (_class = (_temp = class SupplyController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "activeItems", _descriptor, this);

      _initializerDefineProperty(this, "save", _descriptor2, this);
    }

    get changeset() {
      return new _emberChangeset.default(this.model.supply, (0, _emberChangesetValidations.default)(_supply.default), _supply.default);
    }

    get columns() {
      return [{
        name: this.intl.t("models.supply.createdAt"),
        valuePath: "createdAt",
        cellComponent: "data-grid/supply/is-saving",
        width: 150
      }, {
        name: this.intl.t("models.item.name"),
        valuePath: "item.name"
      }, {
        name: this.intl.t("models.supply.quantity"),
        valuePath: "quantity",
        textAlign: "right",
        width: 50
      }, {
        name: this.intl.t("models.supply.purchasePrice"),
        valuePath: "purchasePrice",
        width: 80,
        textAlign: "right",
        cellComponent: "money"
      }];
    }

    get rows() {
      var supplies = this.get("model.supplies").toArray();
      var newSupply = this.get("model.supply");
      supplies.unshiftObject(newSupply);
      return supplies;
    }

    onItemChosen(item) {
      if (!Ember.isEmpty(item)) {
        item.get("lastSupply").then(lastSupply => {
          var args = lastSupply ? {
            quantity: lastSupply.quantity,
            purchasePrice: lastSupply.purchasePrice
          } : {
            quantity: 0,
            purchasePrice: 0
          };
          this.changeset.setProperties(args);
        });
        this.changeset.set("item", item);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "activeItems", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeset", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "changeset"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "columns", [Ember.computed], Object.getOwnPropertyDescriptor(_class.prototype, "columns"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "rows", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "rows"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "save", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onItemChosen", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onItemChosen"), _class.prototype)), _class));
  _exports.default = SupplyController;
});