define("frontend/components/x-note/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zg6t3N/Y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"formVisible\"]]],null,{\"statements\":[[0,\"  \"],[7,\"form\",true],[10,\"class\",\"ui form\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"inline fields\"],[8],[0,\"\\n      \"],[5,\"input\",[[12,\"placeholder\",[28,\"t\",[\"x-note.note\"],null]]],[[\"@type\",\"@value\"],[\"text\",[23,0,[\"tempValue\"]]]]],[0,\"\\n\\n      \"],[7,\"button\",false],[12,\"class\",\"ui primary button\"],[3,\"on\",[\"click\",[23,0,[\"save\"]]]],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"x-note.save\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"p\",false],[12,\"role\",\"button\"],[3,\"on\",[\"click\",[23,0,[\"toggleForm\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"note\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,0,[\"note\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"x-note.noNote\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/components/x-note/template.hbs"
    }
  });

  _exports.default = _default;
});