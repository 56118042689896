define("frontend/registration/terms/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  var TermsController = (_dec = Ember.computed("preference.company.email,preference.{entityNames,entitiesIdentificationNumbers,entitiesHeadquarters}"), (_class = (_temp = class TermsController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "preference", _descriptor, this);
    }

    get textWithReplacements() {
      var replacements = {
        entitiesNames: this.getWithDefault("preference.entitiesNames", []).join(", "),
        entitiesIdentificationNumbers: this.getWithDefault("preference.entitiesIdentificationNumbers", []).join(", "),
        entitiesHeadquarters: this.getWithDefault("preference.entitiesHeadquarters", []).join(", "),
        email: this.get("preference.company.email")
      };
      var {
        model
      } = this;
      Object.keys(replacements).forEach(key => {
        model = model.replace("{{".concat(key, "}}"), replacements[key]);
      });
      return model;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "preference", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "textWithReplacements", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "textWithReplacements"), _class.prototype)), _class));
  _exports.default = TermsController;
});