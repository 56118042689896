define("frontend/components/x-item/search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wg6I2FuV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"power-select\"],[[28,\"component\",[\"power-select\"],[[\"matcher\"],[[23,0,[\"itemMatcher\"]]]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/components/x-item/search/template.hbs"
    }
  });

  _exports.default = _default;
});