define("frontend/admin/overview/controller", ["exports", "frontend/config/environment", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _environment, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  var OverviewController = (_dec = Ember.computed("isEetOk"), _dec2 = Ember.computed("model.revenues.[]"), _dec3 = Ember.computed("model.visits.[]"), _dec4 = Ember.computed("model.visits.[]"), _dec5 = Ember.computed("model.visits.[]"), _dec6 = Ember.computed("model.visits.[]"), _dec7 = Ember.computed("model.visits.[]"), _dec8 = Ember.computed("model.visits.[]"), _dec9 = Ember.computed("model.visits.[]"), _dec10 = Ember.computed("model.visits.[]"), (_class = (_temp = class OverviewController extends Ember.Controller.extend(_fileSaver.default) {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", ["from", "to"]);

      _defineProperty(this, "maxPerPage", 1000);

      _defineProperty(this, "from", null);

      _defineProperty(this, "to", null);

      _initializerDefineProperty(this, "excel", _descriptor, this);
    }

    get links() {
      return {
        now: this.formatDate(moment()),
        day: this.formatDate(moment().subtract(1, "days")),
        week: this.formatDate(moment().startOf("week")),
        month: this.formatDate(moment().startOf("month"))
      };
    }

    formatDate(date) {
      var {
        dateFormat
      } = _environment.default.app;
      return moment(date).format(dateFormat);
    }

    get eetClass() {
      if (this.isEetOk) {
        return "positive";
      } else {
        return "negative";
      }
    }

    get isEetOk() {
      var failedEets = this.get("model.revenues").reduce(function (prev, curr) {
        return prev + (curr.get("eetState") === "failed" ? 1 : 0);
      }, 0);
      return failedEets === 0;
    }

    get isPagingExceeded() {
      return this.get("model.visits.length") >= this.maxPerPage;
    }

    get sumCash() {
      var visits = this.get("model.visits");
      return visits.reduce((prev, curr) => {
        return prev + curr.get("paidInCash");
      }, 0);
    }

    get sumCard() {
      var visits = this.get("model.visits");
      return visits.reduce((prev, curr) => {
        return prev + curr.get("paidByCard");
      }, 0);
    }

    get sumPrice() {
      var visits = this.get("model.visits");
      return visits.reduce((prev, curr) => {
        return prev + curr.get("price");
      }, 0);
    }

    get sumServices() {
      var visits = this.get("model.visits");
      return visits.reduce((prev, curr) => {
        return prev + curr.get("amountServices");
      }, 0);
    }

    get sumSales() {
      var visits = this.get("model.visits");
      return visits.reduce((prev, curr) => {
        return prev + curr.get("amountSales");
      }, 0);
    }

    get sumTips() {
      var visits = this.get("model.visits");
      return visits.reduce((prev, curr) => {
        return prev + curr.get("tip");
      }, 0);
    }

    get sumPriceWithTip() {
      var visits = this.get("model.visits");
      return visits.reduce((prev, curr) => {
        return prev + curr.get("priceWithTip");
      }, 0);
    }

    updateDates(startDate, endDate) {
      this.setProperties({
        from: this.formatDate(startDate),
        to: this.formatDate(endDate)
      });
    }

    exportVisits() {
      var i18n = this.intl;
      var visits = this.get("model.visits").map(function (v) {
        return [v.get("createdAt").toString(), v.get("amountServices"), v.get("amountSales"), v.get("price"), v.get("tip"), v.get("paidByCard"), v.get("paidInCash"), v.get("priceWithTip")];
      });
      var headers = [[i18n.t("overview.date").toString(), i18n.t("overview.amountServices").toString(), i18n.t("overview.amountSales").toString(), i18n.t("overview.price").toString(), i18n.t("overview.tip").toString(), i18n.t("overview.paidByCard").toString(), i18n.t("overview.paidInCash").toString(), i18n.t("overview.priceWithTip").toString()]];
      var data = headers.concat(visits);
      var sheetName = "sheet1";
      var fileName = "zakazky.xlsx";
      this.excel.export(data, {
        sheetName,
        fileName
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "excel", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "links", [Ember.computed], Object.getOwnPropertyDescriptor(_class.prototype, "links"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "eetClass", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "eetClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isEetOk", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isEetOk"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isPagingExceeded", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isPagingExceeded"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sumCash", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "sumCash"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sumCard", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "sumCard"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sumPrice", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "sumPrice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sumServices", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "sumServices"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sumSales", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "sumSales"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sumTips", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "sumTips"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sumPriceWithTip", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "sumPriceWithTip"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateDates", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "updateDates"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "exportVisits", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "exportVisits"), _class.prototype)), _class));
  _exports.default = OverviewController;
});