define("frontend/models/employee", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    firstName: _emberData.default.attr('string', {
      defaultValue: ""
    }),
    lastName: _emberData.default.attr('string', {
      defaultValue: ""
    }),
    photoUrl: _emberData.default.attr('string', {
      defaultValue: ""
    }),
    isActive: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    color: _emberData.default.attr('string', {
      defaultValue: "brown"
    }),
    shareOfSales: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    shareOfServices: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    visits: _emberData.default.hasMany('visit', {
      async: true
    }),
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    fullName: Ember.computed('firstName', 'lastName', function () {
      var firstName = this.firstName;
      var lastName = this.lastName;
      return "".concat(firstName, " ").concat(lastName);
    }),
    shareOfSalesPercent: Ember.computed('shareOfSales', {
      get() {
        return this.shareOfSales * 100;
      },

      set(key, value) {
        this.set('shareOfSales', value / 100);
        return value;
      }

    }),
    shareOfServicesPercent: Ember.computed('shareOfServices', {
      get() {
        return this.shareOfServices * 100;
      },

      set(key, value) {
        this.set('shareOfServices', value / 100);
        return value;
      }

    })
  });

  _exports.default = _default;
});