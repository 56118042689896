define("frontend/initializers/intl", ["exports", "bp-ember-components/initializers/intl"], function (_exports, _intl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "intl",
    initialize: _intl.initialize
  };
  _exports.default = _default;
});