define("frontend/admin/employee/items/controller", ["exports", "frontend/config/environment", "frontend/utils/add-vat"], function (_exports, _environment, _addVat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var ItemsController = (_dec = Ember.computed("model.sells.[]"), _dec2 = Ember.computed("sums.[]"), (_class = (_temp = class ItemsController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", ["from", "to"]);

      _defineProperty(this, "from", null);

      _defineProperty(this, "to", null);
    }

    get sums() {
      var sums = this.get("model.sells").reduce((previousValue, sell) => {
        var item = sell.get("item");
        var itemId = item.get("id");
        var count = sell.get("count");

        if (!previousValue[itemId]) {
          previousValue[itemId] = {
            sumOfPurchasesVAT: 0,
            sumOfPurchases: 0,
            sumOfSells: 0,
            count: 0,
            item
          };
        }

        var obj = previousValue[itemId];
        obj.count += count;
        obj.sumOfSells += count * (sell.get("price") || 0);
        obj.sumOfPurchases += count * (item.get("lastSupply.purchasePrice") || 0);
        obj.sumOfPurchasesVAT += count * (0, _addVat.default)(item.get("lastSupply.purchasePrice"), item.get("lastSupply.vat")) || 0;
        return previousValue;
      }, {});
      return Object.values(sums);
    }

    get sumOfSums() {
      var sumOfSums = this.sums.reduce(function (previousValue, itemSum) {
        previousValue.purchasesVAT += itemSum.sumOfPurchasesVAT;
        previousValue.purchases += itemSum.sumOfPurchases;
        previousValue.sells += itemSum.sumOfSells;
        return previousValue;
      }, {
        purchasesVAT: 0,
        purchases: 0,
        sells: 0
      });
      return sumOfSums;
    }

    updateDates(startDate, endDate) {
      this.setProperties({
        from: moment(startDate).format(_environment.default.app.dateFormat),
        to: moment(endDate).format(_environment.default.app.dateFormat)
      });
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "sums", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "sums"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sumOfSums", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "sumOfSums"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateDates", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "updateDates"), _class.prototype)), _class));
  _exports.default = ItemsController;
});