define("frontend/customers/page-object", ["exports", "bp-ember-components/components/data-grid-page-object", "ember-cli-page-object"], function (_exports, _dataGridPageObject, _emberCliPageObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = CashbookPage;

  function CashbookPage() {
    return (0, _emberCliPageObject.create)({
      visit: (0, _emberCliPageObject.visitable)("/customers"),
      dataGrid: _dataGridPageObject.default
    });
  }
});