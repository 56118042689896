define("frontend/admin/items/stock/route", ["exports", "frontend/routes/protected", "ember-cli-pagination/remote/route-mixin"], function (_exports, _protected, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class StockRoute extends _protected.default.extend(_routeMixin.default) {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", {
        isActive: {
          refreshModel: true
        },
        sort: {
          refreshModel: true
        },
        filter: {
          refreshModel: true
        }
      });
    }

    model(params) {
      var query = {
        filter: {
          isActive: params.isActive,
          fulltext: params.filter
        },
        include: "last-supply,entity",
        sort: params.sort
      };
      return this.findPaged("item", query);
    }

  }

  _exports.default = StockRoute;
});