define("frontend/components/x-customer/visits/visit/sells/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VI+CdXAL",
    "block": "{\"symbols\":[\"s\"],\"statements\":[[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"sells\"]]],null,{\"statements\":[[0,\"    \"],[7,\"tr\",true],[10,\"class\",\"customer-visit-sell\"],[8],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"customer-visit-sell-item-name\"],[8],[0,\"\\n        \"],[1,[23,1,[\"item\",\"name\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"customer-visit-sell-count\"],[8],[0,\"\\n        \"],[1,[23,1,[\"count\"]],false],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"times icon\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"customer-visit-sell-price\"],[8],[0,\"\\n        \"],[5,\"money\",[],[[\"@value\"],[[23,1,[\"price\"]]]]],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"customer-visit-sell-discount\"],[8],[0,\"\\n        -\\n        \"],[1,[23,1,[\"discount\"]],false],[0,\"\\n        %\\n      \"],[9],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"customer-visit-sell-sum\"],[8],[0,\"\\n        =\\n        \"],[5,\"money\",[],[[\"@value\"],[[23,1,[\"sum\"]]]]],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/components/x-customer/visits/visit/sells/template.hbs"
    }
  });

  _exports.default = _default;
});