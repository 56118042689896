define("frontend/models/sell", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    count: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    price: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    discount: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    isService: _emberData.default.attr("boolean"),
    updatedAt: _emberData.default.attr(),
    item: _emberData.default.belongsTo("item", {
      async: true
    }),
    visit: _emberData.default.belongsTo("visit", {
      async: true
    }),
    entity: _emberData.default.belongsTo("entity", {
      async: true
    }),
    // WHeeeeee? Server side computations?
    sum: Ember.computed("count", "price", "discount", function () {
      return this.count * this.price * (1 - this.discount / 100);
    }),
    sumBeforeDiscount: Ember.computed("count", "price", function () {
      return this.count * this.price;
    })
  });

  _exports.default = _default;
});