define("frontend/admin/user/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VPQuW9Sm",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[7,\"h2\",true],[8],[1,[23,0,[\"model\",\"email\"]],false],[9],[0,\"\\n\\n\"],[4,\"form-for\",[[23,0,[\"model\"]]],[[\"class\",\"submit\"],[[28,\"concat\",[\"ui form\",\" \",[28,\"unless\",[[23,0,[\"model\",\"isValid\"]],\"error\"],null]],null],[28,\"action\",[[23,0,[]],\"updateUser\"],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"password-field\"]],\"expected `form.password-field` to be a contextual component but found a string. Did you mean `(component form.password-field)`? ('frontend/admin/user/template.hbs' @ L4:C4) \"],null],\"password\"],null],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[1,[28,\"t\",[\"formFor.user.passwordConfirmation\"],null],false],[9],[0,\"\\n    \"],[5,\"input\",[],[[\"@type\",\"@value\"],[\"password\",[23,0,[\"passwordConfirmation\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[6,[23,1,[\"submit\"]],[],[[\"@disabled\",\"@class\"],[[23,0,[\"isPasswordConfirmationInvalid\"]],\"ui primary button\"]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/admin/user/template.hbs"
    }
  });

  _exports.default = _default;
});