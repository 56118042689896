define("frontend/visit/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  var ShowController = (_dec = Ember.computed.oneWay("model.items.active"), _dec2 = Ember.computed("heap.item"), _dec3 = Ember.computed.empty("model.visit.sells"), _dec4 = Ember.computed("model.visit.sells.@each.hasDirtyAttributes"), (_class = (_temp = class ShowController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "activeItems", _descriptor, this);

      _initializerDefineProperty(this, "isDeleteVisible", _descriptor2, this);
    }

    resetHeap() {
      var sell = this.store.createRecord("sell", {
        count: 1,
        price: 0
      });
      this.set("heap", sell);
    }

    get hasUnsavedSells() {
      return !Ember.isEmpty(this.get("heap.item.content"));
    }

    get sellsInFlight() {
      if (!this.model.visit || !this.model.visit.sells) {
        return false;
      }

      return this.model.visit.sells.any(function (sell) {
        return sell && sell.hasDirtyAttributes;
      });
    }

    createSell() {
      var visit = this.get("model.visit");
      var sell = this.heap;
      var name = sell.get("item.name");
      var fullName = this.get("model.customer.fullName");
      var flash = this.flashMessages;
      sell.set("visit", visit);
      return sell.save().then(() => {
        flash.success("".concat(name, " \u2713 ").concat(fullName));
        visit.reload();
        this.resetHeap();
      }, response => {
        sell.unloadRecord();
        flash.danger("".concat(name, " \u2713 ").concat(fullName, " - ").concat(response.message));
      });
    }

    deleteSell(sell) {
      var visit = this.get("model.visit");
      var flash = this.flashMessages;
      var name = sell.get("item.name");
      var fullName = this.get("model.customer.fullName");
      sell.deleteRecord();
      return sell.save().then(() => {
        visit.reload();
        flash.success("".concat(name, " \u2717 ").concat(fullName));
      }, response => {
        flash.danger("".concat(name, " \u232B ").concat(fullName, " ").concat(response.message));
      });
    }

    updateSell(sell) {
      var visit = this.get("model.visit");
      var flash = this.flashMessages;
      var name = sell.get("item.name");
      var fullName = this.get("model.customer.fullName");
      return sell.save().then(() => {
        visit.reload();
        flash.success("".concat(name, " \u270E ").concat(fullName));
      }, response => {
        flash.danger("".concat(name, " \u270E ").concat(fullName, " ").concat(response.message));
      });
    }

    deleteVisit() {
      var visit = this.get("model.visit");
      visit.deleteRecord();
      visit.save().then(() => {
        this.replaceRoute("visits");
      });
    }

    copyDiscount() {
      var visit = this.get("model.visit");
      var allSells = this.model.visit.sells;
      var firstSell = allSells.firstObject;
      var {
        discount
      } = firstSell;
      var pending = allSells.map(function (sell) {
        if (sell.discount !== discount) {
          sell.set("discount", discount);
          return sell.save();
        }
      });
      Ember.RSVP.all(pending).then(function () {
        visit.reload();
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "activeItems", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "hasUnsavedSells", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "hasUnsavedSells"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isDeleteVisible", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sellsInFlight", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "sellsInFlight"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createSell", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "createSell"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteSell", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteSell"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSell", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "updateSell"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteVisit", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteVisit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "copyDiscount", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "copyDiscount"), _class.prototype)), _class));
  _exports.default = ShowController;
});