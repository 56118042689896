define("frontend/cldrs/cs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*jslint eqeq: true*/
  var _default = [{
    "locale": "cs",
    "pluralRuleFunction": function pluralRuleFunction(n, ord) {
      var s = String(n).split("."),
          i = s[0],
          v0 = !s[1];
      if (ord) return "other";
      return n == 1 && v0 ? "one" : i >= 2 && i <= 4 && v0 ? "few" : !v0 ? "many" : "other";
    },
    "fields": {
      "year": {
        "displayName": "rok",
        "relative": {
          "0": "tento rok",
          "1": "příští rok",
          "-1": "minulý rok"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} rok",
            "few": "za {0} roky",
            "many": "za {0} roku",
            "other": "za {0} let"
          },
          "past": {
            "one": "před {0} rokem",
            "few": "před {0} lety",
            "many": "před {0} roku",
            "other": "před {0} lety"
          }
        }
      },
      "year-short": {
        "displayName": "r.",
        "relative": {
          "0": "tento rok",
          "1": "příští rok",
          "-1": "minulý rok"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} r.",
            "few": "za {0} r.",
            "many": "za {0} r.",
            "other": "za {0} l."
          },
          "past": {
            "one": "před {0} r.",
            "few": "před {0} r.",
            "many": "před {0} r.",
            "other": "před {0} l."
          }
        }
      },
      "month": {
        "displayName": "měsíc",
        "relative": {
          "0": "tento měsíc",
          "1": "příští měsíc",
          "-1": "minulý měsíc"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} měsíc",
            "few": "za {0} měsíce",
            "many": "za {0} měsíce",
            "other": "za {0} měsíců"
          },
          "past": {
            "one": "před {0} měsícem",
            "few": "před {0} měsíci",
            "many": "před {0} měsíce",
            "other": "před {0} měsíci"
          }
        }
      },
      "month-short": {
        "displayName": "měs.",
        "relative": {
          "0": "tento měs.",
          "1": "příští měs.",
          "-1": "minulý měs."
        },
        "relativeTime": {
          "future": {
            "one": "za {0} měs.",
            "few": "za {0} měs.",
            "many": "za {0} měs.",
            "other": "za {0} měs."
          },
          "past": {
            "one": "před {0} měs.",
            "few": "před {0} měs.",
            "many": "před {0} měs.",
            "other": "před {0} měs."
          }
        }
      },
      "day": {
        "displayName": "den",
        "relative": {
          "0": "dnes",
          "1": "zítra",
          "2": "pozítří",
          "-2": "předevčírem",
          "-1": "včera"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} den",
            "few": "za {0} dny",
            "many": "za {0} dne",
            "other": "za {0} dní"
          },
          "past": {
            "one": "před {0} dnem",
            "few": "před {0} dny",
            "many": "před {0} dne",
            "other": "před {0} dny"
          }
        }
      },
      "day-short": {
        "displayName": "den",
        "relative": {
          "0": "dnes",
          "1": "zítra",
          "2": "pozítří",
          "-2": "předevčírem",
          "-1": "včera"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} den",
            "few": "za {0} dny",
            "many": "za {0} dne",
            "other": "za {0} dní"
          },
          "past": {
            "one": "před {0} dnem",
            "few": "před {0} dny",
            "many": "před {0} dne",
            "other": "před {0} dny"
          }
        }
      },
      "hour": {
        "displayName": "hodina",
        "relative": {
          "0": "tuto hodinu"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} hodinu",
            "few": "za {0} hodiny",
            "many": "za {0} hodiny",
            "other": "za {0} hodin"
          },
          "past": {
            "one": "před {0} hodinou",
            "few": "před {0} hodinami",
            "many": "před {0} hodiny",
            "other": "před {0} hodinami"
          }
        }
      },
      "hour-short": {
        "displayName": "h",
        "relative": {
          "0": "tuto hodinu"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} h",
            "few": "za {0} h",
            "many": "za {0} h",
            "other": "za {0} h"
          },
          "past": {
            "one": "před {0} h",
            "few": "před {0} h",
            "many": "před {0} h",
            "other": "před {0} h"
          }
        }
      },
      "minute": {
        "displayName": "minuta",
        "relative": {
          "0": "tuto minutu"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} minutu",
            "few": "za {0} minuty",
            "many": "za {0} minuty",
            "other": "za {0} minut"
          },
          "past": {
            "one": "před {0} minutou",
            "few": "před {0} minutami",
            "many": "před {0} minuty",
            "other": "před {0} minutami"
          }
        }
      },
      "minute-short": {
        "displayName": "min",
        "relative": {
          "0": "tuto minutu"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} min",
            "few": "za {0} min",
            "many": "za {0} min",
            "other": "za {0} min"
          },
          "past": {
            "one": "před {0} min",
            "few": "před {0} min",
            "many": "před {0} min",
            "other": "před {0} min"
          }
        }
      },
      "second": {
        "displayName": "sekunda",
        "relative": {
          "0": "nyní"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} sekundu",
            "few": "za {0} sekundy",
            "many": "za {0} sekundy",
            "other": "za {0} sekund"
          },
          "past": {
            "one": "před {0} sekundou",
            "few": "před {0} sekundami",
            "many": "před {0} sekundy",
            "other": "před {0} sekundami"
          }
        }
      },
      "second-short": {
        "displayName": "s",
        "relative": {
          "0": "nyní"
        },
        "relativeTime": {
          "future": {
            "one": "za {0} s",
            "few": "za {0} s",
            "many": "za {0} s",
            "other": "za {0} s"
          },
          "past": {
            "one": "před {0} s",
            "few": "před {0} s",
            "many": "před {0} s",
            "other": "před {0} s"
          }
        }
      }
    },
    "numbers": {
      "decimal": {
        "long": [[1000, {
          "one": ["0 tisíc", 1],
          "few": ["0 tisíce", 1],
          "many": ["0 tisíce", 1],
          "other": ["0 tisíc", 1]
        }], [10000, {
          "one": ["00 tisíc", 2],
          "few": ["00 tisíc", 2],
          "many": ["00 tisíce", 2],
          "other": ["00 tisíc", 2]
        }], [100000, {
          "one": ["000 tisíc", 3],
          "few": ["000 tisíc", 3],
          "many": ["000 tisíce", 3],
          "other": ["000 tisíc", 3]
        }], [1000000, {
          "one": ["0 milion", 1],
          "few": ["0 miliony", 1],
          "many": ["0 milionu", 1],
          "other": ["0 milionů", 1]
        }], [10000000, {
          "one": ["00 milionů", 2],
          "few": ["00 milionů", 2],
          "many": ["00 milionu", 2],
          "other": ["00 milionů", 2]
        }], [100000000, {
          "one": ["000 milionů", 3],
          "few": ["000 milionů", 3],
          "many": ["000 milionu", 3],
          "other": ["000 milionů", 3]
        }], [1000000000, {
          "one": ["0 miliarda", 1],
          "few": ["0 miliardy", 1],
          "many": ["0 miliardy", 1],
          "other": ["0 miliard", 1]
        }], [10000000000, {
          "one": ["00 miliard", 2],
          "few": ["00 miliard", 2],
          "many": ["00 miliardy", 2],
          "other": ["00 miliard", 2]
        }], [100000000000, {
          "one": ["000 miliard", 3],
          "few": ["000 miliard", 3],
          "many": ["000 miliardy", 3],
          "other": ["000 miliard", 3]
        }], [1000000000000, {
          "one": ["0 bilion", 1],
          "few": ["0 biliony", 1],
          "many": ["0 bilionu", 1],
          "other": ["0 bilionů", 1]
        }], [10000000000000, {
          "one": ["00 bilionů", 2],
          "few": ["00 bilionů", 2],
          "many": ["00 bilionu", 2],
          "other": ["00 bilionů", 2]
        }], [100000000000000, {
          "one": ["000 bilionů", 3],
          "few": ["000 bilionů", 3],
          "many": ["000 bilionu", 3],
          "other": ["000 bilionů", 3]
        }]],
        "short": [[1000, {
          "one": ["0 tis'.'", 1],
          "few": ["0 tis'.'", 1],
          "many": ["0 tis'.'", 1],
          "other": ["0 tis'.'", 1]
        }], [10000, {
          "one": ["00 tis'.'", 2],
          "few": ["00 tis'.'", 2],
          "many": ["00 tis'.'", 2],
          "other": ["00 tis'.'", 2]
        }], [100000, {
          "one": ["000 tis'.'", 3],
          "few": ["000 tis'.'", 3],
          "many": ["000 tis'.'", 3],
          "other": ["000 tis'.'", 3]
        }], [1000000, {
          "one": ["0 mil'.'", 1],
          "few": ["0 mil'.'", 1],
          "many": ["0 mil'.'", 1],
          "other": ["0 mil'.'", 1]
        }], [10000000, {
          "one": ["00 mil'.'", 2],
          "few": ["00 mil'.'", 2],
          "many": ["00 mil'.'", 2],
          "other": ["00 mil'.'", 2]
        }], [100000000, {
          "one": ["000 mil'.'", 3],
          "few": ["000 mil'.'", 3],
          "many": ["000 mil'.'", 3],
          "other": ["000 mil'.'", 3]
        }], [1000000000, {
          "one": ["0 mld'.'", 1],
          "few": ["0 mld'.'", 1],
          "many": ["0 mld'.'", 1],
          "other": ["0 mld'.'", 1]
        }], [10000000000, {
          "one": ["00 mld'.'", 2],
          "few": ["00 mld'.'", 2],
          "many": ["00 mld'.'", 2],
          "other": ["00 mld'.'", 2]
        }], [100000000000, {
          "one": ["000 mld'.'", 3],
          "few": ["000 mld'.'", 3],
          "many": ["000 mld'.'", 3],
          "other": ["000 mld'.'", 3]
        }], [1000000000000, {
          "one": ["0 bil'.'", 1],
          "few": ["0 bil'.'", 1],
          "many": ["0 bil'.'", 1],
          "other": ["0 bil'.'", 1]
        }], [10000000000000, {
          "one": ["00 bil'.'", 2],
          "few": ["00 bil'.'", 2],
          "many": ["00 bil'.'", 2],
          "other": ["00 bil'.'", 2]
        }], [100000000000000, {
          "one": ["000 bil'.'", 3],
          "few": ["000 bil'.'", 3],
          "many": ["000 bil'.'", 3],
          "other": ["000 bil'.'", 3]
        }]]
      }
    }
  }];
  _exports.default = _default;
});