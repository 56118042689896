define("frontend/helpers/purchase-price-with-vat", ["exports", "frontend/utils/add-vat"], function (_exports, _addVat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function purchasePriceWithVat(params
  /*, hash*/
  ) {
    var [purchasePrice, vat] = params;
    return (0, _addVat.default)(purchasePrice, vat);
  });

  _exports.default = _default;
});