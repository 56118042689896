define("frontend/components/x-visit/total-price/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wD1W93d2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"visit\",\"isReloading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"notched circle loading icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"money\",[],[[\"@value\"],[[23,0,[\"visit\",\"price\"]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/components/x-visit/total-price/template.hbs"
    }
  });

  _exports.default = _default;
});