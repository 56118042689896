define("frontend/components/x-customer/visits/visit/sells/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  var Sells = (_dec = (0, _component.tagName)("table"), _dec2 = (0, _component.classNames)("ui stacking very basic compact table"), _dec(_class = _dec2(_class = class Sells extends Ember.Component {}) || _class) || _class);
  _exports.default = Sells;
});