define("frontend/components/sell-entity-sum/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JvQC+svb",
    "block": "{\"symbols\":[],\"statements\":[[5,\"money\",[],[[\"@value\"],[[23,0,[\"sum\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/components/sell-entity-sum/template.hbs"
    }
  });

  _exports.default = _default;
});