define("frontend/admin/customers/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  var CustomersController = (_dec = (0, _emberConcurrency.task)(function* (filter) {
    yield (0, _emberConcurrency.timeout)(400);
    this.set("filter", filter);
  }).restartable(), _dec2 = Ember.computed.oneWay("model"), _dec3 = Ember.computed("model.[]"), (_class = (_temp = class CustomersController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", ["sort", "filter", "page", "perPage"]);

      _defineProperty(this, "sort", "lastName");

      _initializerDefineProperty(this, "excel", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _initializerDefineProperty(this, "rows", _descriptor3, this);
    }

    get columns() {
      return [{
        name: this.intl.t("models.customer.isApproved"),
        valuePath: "isApproved",
        cellComponent: "user-approved",
        width: 80
      }, {
        name: this.intl.t("models.customer.firstName"),
        valuePath: "firstName",
        width: 150
      }, {
        name: this.intl.t("models.customer.lastName"),
        valuePath: "lastName",
        width: 150
      }, {
        name: this.intl.t("models.customer.phone"),
        valuePath: "phone",
        width: 150
      }, {
        name: this.intl.t("models.customer.mail"),
        valuePath: "mail"
      }, {
        name: this.intl.t("models.customer.lastVisitDate"),
        valuePath: "lastVisitDate",
        cellComponent: "x-visit/last-visit",
        width: 150
      }, {
        name: this.intl.t("models.customer.visitsCount"),
        valuePath: "visitsCount",
        width: 150
      }, {
        name: this.intl.t("models.customer.birth"),
        valuePath: "birth",
        cellComponent: "x-customer/birthday",
        width: 150,
        isSortable: false
      }];
    }

    get mailto() {
      var mails = this.model.mapBy("mail").join(",");
      return "mailto:salon@topstylesalon.cz?subject=TopStyleSalon&bcc=".concat(mails);
    }

    rowClicked(row) {
      this.transitionToRoute("admin.customer", row.rowValue.id);
    }

    createCustomer() {
      this.store.createRecord("customer").save().then(customer => {
        this.transitionToRoute("admin.customer.edit", customer.id);
      });
    }

    exportCustomers() {
      var customers = this.model.map(function (c) {
        var birth = c.get("birth") ? c.get("birth").toString() : "";
        return ["".concat(c.get("firstName"), " ").concat(c.get("lastName")), c.get("firstName"), c.get("lastName"), "", "", "", "", "", "", "", "", "", "", birth, c.get("gender"), "", "", "", "", "", "", "", "", "", c.get("note"), "", "Email", c.get("mail"), "", "", "", "", "", "Mobile", c.get("phone")];
      });
      var headers = [["Name", "Given Name", "Family Name", "Yomi Name", "Given Name Yomi", "Additional Name Yomi", "Family Name Yomi", "Name Prefix", "Name Suffix", "Initials", "Nickname", "Short Name", "Maiden Name", "Birthday", "Gender", "Location", "Billing Information", "Directory Server", "Mileage", "Occupation", "Hobby", "Sensitivity", "Priority", "Subject", "Notes", "Group Membership", "E-mail 1 - Type", "E-mail 1 - Value", "E-mail 2 - Type", "E-mail 2 - Value", "IM 1 - Type", "IM 1 - Service", "IM 1 - Value", "Phone 1 - Type", "Phone 1 - Value", "Phone 2 - Type", "Phone 2 - Value", "Address 1 - Type", "Address 1 - Formatted", "Address 1 - Street", "Address 1 - City", "Address 1 - PO Box", "Address 1 - Region", "Address 1 - Postal Code", "Address 1 - Country", "Address 1 - Extended Address", "Website 1 - Type", "Website 1 - Value", "Website 2 - Type", "Website 2 - Value"]];
      var data = headers.concat(customers);
      var sheetName = "sheet1";
      var fileName = "zakaznici.xlsx";
      this.excel.export(data, {
        sheetName,
        fileName
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "excel", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "columns", [Ember.computed], Object.getOwnPropertyDescriptor(_class.prototype, "columns"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "rows", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "mailto", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "mailto"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "rowClicked", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "rowClicked"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createCustomer", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "createCustomer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "exportCustomers", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "exportCustomers"), _class.prototype)), _class));
  _exports.default = CustomersController;
});