define("frontend/components/page-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GBAvnMHU",
    "block": "{\"symbols\":[\"@backLink\",\"@backText\",\"@headingText\"],\"statements\":[[7,\"h2\",true],[8],[0,\"\\n  \"],[1,[23,3,[]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"  \"],[5,\"link-to\",[],[[\"@route\"],[[23,1,[]]]],{\"statements\":[[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"left chevron icon\"],[8],[9],[0,\"\\n    \"],[1,[23,2,[]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/components/page-header/template.hbs"
    }
  });

  _exports.default = _default;
});