define("frontend/customer/show/route", ["exports", "frontend/routes/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ShowRoute extends _protected.default {
    model(params) {
      return this.store.findRecord("customer", params.id);
    }

  }

  _exports.default = ShowRoute;
});