define("frontend/models/visit", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    priceWithTip: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    receivedCash: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    paidInCash: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    paidByCard: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    price: _emberData.default.attr("number", {
      defaultValue: 0,
      readOnly: true
    }),
    updatedAt: _emberData.default.attr("date"),
    createdAt: _emberData.default.attr("date"),
    completed: _emberData.default.attr(),
    note: _emberData.default.attr(),
    amountSales: _emberData.default.attr("number", {
      defaultValue: 0,
      readOnly: true
    }),
    amountServices: _emberData.default.attr("number", {
      defaultValue: 0,
      readOnly: true
    }),
    employeeShareOfSales: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    employeeShareOfServices: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    customer: _emberData.default.belongsTo("customer", {
      async: true,
      inverse: "visits"
    }),
    employee: _emberData.default.belongsTo("employee", {
      async: true
    }),
    sells: _emberData.default.hasMany("sell"),
    revenues: _emberData.default.hasMany("revenue", {
      async: true
    }),
    cashbookEntry: _emberData.default.belongsTo("cashbookEntry", {
      async: true
    }),
    tip: Ember.computed("priceWithTip", "price", function () {
      return this.priceWithTip - this.price;
    }),
    employeeShare: Ember.computed("employeeShareSales", "employeeShareServices", function () {
      return this.employeeShareSales + this.employeeShareServices;
    }),
    employeeShareSales: Ember.computed("amountSales", "employeeShareOfSales", function () {
      return this.amountSales * this.employeeShareOfSales;
    }),
    employeeShareServices: Ember.computed("amountServices", "employeeShareOfServices", function () {
      return this.amountServices * this.employeeShareOfServices;
    })
  });

  _exports.default = _default;
});