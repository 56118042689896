define("frontend/registration/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E9HhP6vy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui container margin-top\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui grid\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"ui huge header\"],[3,\"on\",[\"click\",[23,0,[\"tryLogout\"]]]],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"registration.heading\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n        \"],[1,[22,\"outlet\"],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/registration/template.hbs"
    }
  });

  _exports.default = _default;
});