define("frontend/components/version-checker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vJoVHoo1",
    "block": "{\"symbols\":[\"newVersion\",\"oldVersion\",\"reload\"],\"statements\":[[5,\"new-version-notifier\",[],[[\"@updateInterval\"],[600000]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"version-checker\"],[10,\"role\",\"alert\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"versionChecker.textReload\"],null],false],[0,\":\\n    \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"role\",\"button\"],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[23,0,[\"reload\"]]]],[8],[1,[28,\"t\",[\"versionChecker.reload\"],null],false],[9],[0,\".\\n    \"],[1,[28,\"t\",[\"versionChecker.textChangelog\"],null],false],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"changelog\"]],{\"statements\":[[1,[28,\"t\",[\"versionChecker.changelog\"],null],false]],\"parameters\":[]}],[0,\".\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2,3]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/components/version-checker/template.hbs"
    }
  });

  _exports.default = _default;
});