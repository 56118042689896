define("frontend/registration/index/controller", ["exports", "ember-concurrency", "bp-ember-components/validations/customer", "ember-changeset", "ember-changeset-validations"], function (_exports, _emberConcurrency, _customer, _emberChangeset, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  var IndexController = (_dec = (0, _emberConcurrency.task)(function* (customer) {
    yield (0, _emberConcurrency.timeout)(10000);
    var flash = this.flashMessages;
    customer.save().then(() => {
      flash.positive(this.intl.t("registration.success"), {
        timeout: 35000,
        sticky: false
      });
      this.resetForm();
    }, err => {
      var text = this.intl.t("registration.failure");
      var details = err.errors && err.errors[0] && err.errors[0].meta && err.errors[0].meta.exception;
      flash.negative("".concat(text, ": ").concat(details), {
        timeout: 35000,
        sticky: false
      });
    });
  }).drop(), (_class = (_temp = class IndexController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _defineProperty(this, "logoutAttempts", 0);

      _initializerDefineProperty(this, "saveCustomer", _descriptor, this);
    }

    resetForm() {
      var model = this.store.createRecord("customer");
      var changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_customer.default), _customer.default);
      this.setProperties({
        model,
        changeset
      });
    }

    init() {
      super.init(...arguments); // const intl = this.get('intl');

      this.genders = ["male", "female"]; // this.genderRadios = [
      //   { key: 'male', label: intl.t('models.customer.genders.male') },
      //   { key: 'female', label: intl.t('models.customer.genders.female') },
      // ];

      this.resetForm();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "saveCustomer", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = IndexController;
});