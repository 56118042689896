define("frontend/cashbook/page-object", ["exports", "bp-ember-components/components/data-grid-page-object", "ember-cli-page-object"], function (_exports, _dataGridPageObject, _emberCliPageObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = CashbookPage;

  function CashbookPage() {
    return (0, _emberCliPageObject.create)({
      visit: (0, _emberCliPageObject.visitable)("/cashbook"),
      dataGrid: _dataGridPageObject.default,
      visitEntries: (0, _emberCliPageObject.collection)("[data-test-ember-table] tbody tr.visit"),
      manualEntries: (0, _emberCliPageObject.collection)("[data-test-ember-table] tbody tr.manual"),
      fillAmount: (0, _emberCliPageObject.fillable)('form input[name="amount"]'),
      fillNote: (0, _emberCliPageObject.fillable)('form input[name="note"]'),
      submitNew: (0, _emberCliPageObject.clickable)('form button[type="submit"]')
    });
  }
});