define("frontend/admin/item/supplies/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SuppliesController extends Ember.Controller {}

  _exports.default = SuppliesController;
});