define("frontend/models/notification", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    message: _emberData.default.attr("string"),
    status: _emberData.default.attr("string"),
    phone: _emberData.default.attr("string"),
    sendAt: _emberData.default.attr("date"),
    sentSms: _emberData.default.attr("number"),
    statusMessage: _emberData.default.attr("string"),
    notifiable: _emberData.default.belongsTo("reservation", {
      inverse: "notification"
    })
  });

  _exports.default = _default;
});