define("frontend/models/item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    sellingPrice: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    bought: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    sold: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    unlimited: _emberData.default.attr('boolean'),
    barcode: _emberData.default.attr('string'),
    warningThreshold: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    isActive: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    isService: _emberData.default.attr('boolean'),
    sells: _emberData.default.hasMany('sell', {
      async: true
    }),
    supplies: _emberData.default.hasMany('supply', {
      async: true
    }),
    lastSupply: _emberData.default.belongsTo('supply', {
      async: true,
      inverse: 'lastSupplyFor'
    }),
    entity: _emberData.default.belongsTo('entity', {
      async: true
    }),
    lowStock: Ember.computed('stock', 'warningThreshold', function () {
      return this.stock <= this.warningThreshold;
    }),
    scannerString: Ember.computed('name', 'barcode', function () {
      var name = this.name;
      var barcode = this.barcode;

      if (barcode) {
        return "".concat(name, " - ").concat(barcode);
      } else {
        return name;
      }
    }),
    stock: Ember.computed('bought', 'sold', function () {
      return this.bought - this.sold;
    })
  });

  _exports.default = _default;
});