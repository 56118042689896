define("frontend/routes/public", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PublicRoute extends Ember.Route.extend(_unauthenticatedRouteMixin.default) {}

  _exports.default = PublicRoute;
});