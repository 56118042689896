define("frontend/models/customer", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    firstName: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    lastName: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    note: _emberData.default.attr("string"),
    birth: _emberData.default.attr("date", {
      defaultValue: null
    }),
    phone: _emberData.default.attr("string"),
    mail: _emberData.default.attr("string"),
    visitsCount: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    lastVisitDate: _emberData.default.attr("date"),
    gender: _emberData.default.attr("string"),
    isApproved: _emberData.default.attr("boolean", {
      defaultValue: true
    }),
    visits: _emberData.default.hasMany("visit", {
      async: true
    }),
    isActive: Ember.computed.alias("isApproved"),
    fullName: Ember.computed("firstName", "lastName", function () {
      return "".concat(this.firstName, " ").concat(this.lastName);
    }),
    nextBirthday: Ember.computed("birth", function () {
      var birth = this.birth;

      if (Ember.isNone(birth)) {
        return undefined;
      }

      var nextBirthDay = (0, _moment.default)(birth).year((0, _moment.default)().year());

      if ((0, _moment.default)().diff(nextBirthDay) > 0) {
        nextBirthDay.add("1", "years");
      }

      return nextBirthDay;
    }),
    daysTillNextBirthday: Ember.computed("nextBirthday", function () {
      var nextBirthday = this.nextBirthday;

      if (Ember.isNone(nextBirthday)) {
        // This is a little hack needed to be able to sort by this property
        // It's not possible to have birthday in 366 days and it's fine to
        // sort those records at the end of list by having so big value there.
        return 366;
      } else {
        return nextBirthday.diff((0, _moment.default)(), "days");
      }
    })
  });

  _exports.default = _default;
});