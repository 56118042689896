define("frontend/components/x-time-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k4iHrD4H",
    "block": "{\"symbols\":[\"minute\",\"hour\",\"@label\"],\"statements\":[[7,\"label\",true],[8],[0,\"\\n  \"],[1,[23,3,[]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n  \"],[5,\"power-select\",[],[[\"@searchEnabled\",\"@selected\",\"@triggerClass\",\"@options\",\"@onchange\"],[false,[23,0,[\"valueHours\"]],\"ui selection dropdown\",[23,0,[\"hourOptions\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"valueHours\"]]],null]],null]]],{\"statements\":[[0,\"\\n    \"],[1,[23,2,[]],false],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n  \"],[5,\"power-select\",[],[[\"@searchEnabled\",\"@selected\",\"@triggerClass\",\"@options\",\"@onchange\"],[false,[23,0,[\"valueMinutes\"]],\"ui selection dropdown\",[23,0,[\"minuteOptions\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"valueMinutes\"]]],null]],null]]],{\"statements\":[[0,\"\\n    \"],[1,[23,1,[]],false],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/components/x-time-field/template.hbs"
    }
  });

  _exports.default = _default;
});