define("frontend/models/cashbook-entry", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    amount: _emberData.default.attr('number'),
    kind: _emberData.default.attr('string'),
    touchedAt: _emberData.default.attr('date'),
    note: _emberData.default.attr('string'),
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    visit: _emberData.default.belongsTo('visit', {
      async: true
    })
  });

  _exports.default = _default;
});