define("frontend/helpers/margin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.margin = margin;
  _exports.default = void 0;

  function margin(params) {
    var [sellingPrice, purchasePriceWithVat] = params;

    if (purchasePriceWithVat === 0 || Ember.isNone(sellingPrice) || Ember.isNone(purchasePriceWithVat)) {
      return "?";
    }

    var roughMargin = (sellingPrice - purchasePriceWithVat) / purchasePriceWithVat * 100;
    var margin = Math.round(roughMargin).toFixed(1);
    return margin;
  }

  var _default = Ember.Helper.helper(margin);

  _exports.default = _default;
});