define("frontend/models/entity", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    vatid: _emberData.default.attr('string', {
      defaultValue: ""
    }),
    premisesid: _emberData.default.attr('string', {
      defaultValue: ""
    }),
    registerid: _emberData.default.attr('string', {
      defaultValue: ""
    }),
    sendEet: _emberData.default.attr('boolean'),
    headquarters: _emberData.default.attr('string'),
    identificationNumber: _emberData.default.attr('string'),
    businessRegister: _emberData.default.attr('string'),
    isVatPayer: _emberData.default.attr('boolean'),
    // certificate: DS.attr('string'),
    // certificate_password: DS.attr('string')
    company: _emberData.default.belongsTo('company')
  });

  _exports.default = _default;
});