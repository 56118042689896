define("frontend/admin/item/supplies/route", ["exports", "frontend/routes/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SuppliesRoute extends _protected.default {
    model() {
      var {
        id
      } = this.paramsFor("admin.item");
      var query = {
        include: "supplies"
      };
      return this.store.findRecord("item", id, query);
    }

  }

  _exports.default = SuppliesRoute;
});