define("frontend/admin/item/sells/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iSTzv7LZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[11,\"href\",[29,[\"mailto:\\n    \",[23,0,[\"mailtoTo\"]],\"\\n     ?bcc=\\n    \",[23,0,[\"mailtoBcc\"]],\"\\n     &subject=\\n    \",[23,0,[\"mailtoSubject\"]],\"\\n     &body=\\n    \",[23,0,[\"mailtoBody\"]]]]],[10,\"class\",\"ui primary button\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"admin.item.mailtoLabel\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"data-grid\",[],[[\"@columns\",\"@rows\",\"@sort\",\"@fillColumnIndex\",\"@onUpdateSort\",\"@onRowClick\"],[[23,0,[\"columns\"]],[23,0,[\"rows\"]],[23,0,[\"sort\"]],0,[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"sort\"]]],null]],null],[28,\"action\",[[23,0,[]],\"rowClicked\"],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/admin/item/sells/template.hbs"
    }
  });

  _exports.default = _default;
});