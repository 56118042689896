define("frontend/registration/gdpr/route", ["exports", "frontend/routes/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class GdprRoute extends _protected.default {
    model() {
      return Ember.$.get("/GDPR.cs.md", function (data) {
        return data;
      });
    }

  }

  _exports.default = GdprRoute;
});