define("frontend/components/ember-cli-filterable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ehItxxL/",
    "block": "{\"symbols\":[\"@label\"],\"statements\":[[7,\"label\",true],[8],[1,[23,1,[]],false],[0,\":\"],[9],[0,\"\\n\"],[5,\"input\",[[12,\"class\",\"form-control\"],[12,\"placeholder\",[23,0,[\"placeholder\"]]]],[[\"@value\"],[[23,0,[\"filterString\"]]]]],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"isWaiting\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"refresh icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/components/ember-cli-filterable/template.hbs"
    }
  });

  _exports.default = _default;
});