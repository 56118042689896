define("frontend/admin/item/sells/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var SellsController = (_dec = Ember.computed("model.item.name"), _dec2 = Ember.computed("allMails.[]"), _dec3 = Ember.computed.map("model.sells", function (sell) {
    return sell.get("visit.customer.mail");
  }), _dec4 = Ember.computed.oneWay("model.sells"), (_class = (_temp = class SellsController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", ["sort", "page", "perPage"]);

      _defineProperty(this, "sort", "-visit.createdAt");

      _defineProperty(this, "mailtoTo", "moje@firma.cz");

      _initializerDefineProperty(this, "allMails", _descriptor, this);

      _initializerDefineProperty(this, "rows", _descriptor2, this);
    }

    get mailtoSubject() {
      var name = this.get("model.item.name");
      var subject = this.intl.t("admin.item.mailtoSubject", {
        name
      });
      return encodeURIComponent(subject);
    }

    get mailtoBcc() {
      return this.allMails.filter(Boolean).join(",");
    }

    get mailtoBody() {
      var name = this.get("model.item.name");
      var body = this.intl.t("admin.item.mailtoBody", {
        name
      });
      return encodeURIComponent(body);
    }

    get columns() {
      return [{
        name: this.intl.t("models.sell.count"),
        valuePath: "count",
        width: 80
      }, {
        name: this.intl.t("models.sell.price"),
        valuePath: "price",
        cellComponent: "money",
        width: 150
      }, {
        name: this.intl.t("models.sell.sum"),
        valuePath: "sum",
        cellComponent: "money",
        width: 150
      }, {
        name: this.intl.t("models.customer._modelName", {
          count: 1
        }),
        valuePath: "visit.customer.fullName",
        width: 150
      }, {
        name: this.intl.t("models.employee._modelName", {
          count: 1
        }),
        valuePath: "visit.employee.fullName",
        width: 150
      }, {
        name: this.intl.t("createdAt"),
        valuePath: "visit.createdAt",
        cellComponent: "x-date/medium",
        width: 150
      }];
    }

    rowClicked(row) {
      this.transitionToRoute("admin.visit", row.rowValue.get("visit.id"));
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "mailtoSubject", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "mailtoSubject"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mailtoBcc", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "mailtoBcc"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mailtoBody", [Ember.computed], Object.getOwnPropertyDescriptor(_class.prototype, "mailtoBody"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "allMails", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "columns", [Ember.computed], Object.getOwnPropertyDescriptor(_class.prototype, "columns"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rows", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "rowClicked", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "rowClicked"), _class.prototype)), _class));
  _exports.default = SellsController;
});