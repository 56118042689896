define("frontend/components/item-import-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TBh/Sq5k",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[8],[0,\"\\n  \"],[5,\"input\",[],[[\"@type\",\"@value\"],[\"text\",[28,\"mut\",[[23,0,[\"item\",\"name\"]]],null]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[8],[0,\"\\n  \"],[5,\"input\",[],[[\"@type\",\"@value\"],[\"text\",[28,\"mut\",[[23,0,[\"item\",\"barcode\"]]],null]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[8],[0,\"\\n  \"],[5,\"input\",[],[[\"@type\",\"@value\"],[\"text\",[28,\"mut\",[[23,0,[\"item\",\"sellingPrice\"]]],null]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"ui basic icon button\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"delete\"]],[23,0,[\"item\"]]],null]]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"trash icon\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/components/item-import-row/template.hbs"
    }
  });

  _exports.default = _default;
});