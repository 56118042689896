define("frontend/formats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    time: {
      hhmmss: {
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
      },
      hhmm: {
        hour: "numeric",
        minute: "numeric"
      }
    },
    date: {
      hhmmss: {
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
      }
    },
    number: {
      EUR: {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      },
      USD: {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    }
  };
  _exports.default = _default;
});