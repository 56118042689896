define("frontend/admin/notifications/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QQBD4kaC",
    "block": "{\"symbols\":[],\"statements\":[[5,\"interval-picker\",[],[[\"@from\",\"@to\",\"@datesUpdated\"],[[23,0,[\"from\"]],[23,0,[\"to\"]],[28,\"action\",[[23,0,[]],\"updateDates\"],null]]]],[0,\"\\n\\n\"],[5,\"data-grid\",[],[[\"@columns\",\"@rows\",\"@sort\",\"@fillColumnIndex\",\"@onUpdateSort\"],[[23,0,[\"columns\"]],[23,0,[\"rows\"]],[23,0,[\"sort\"]],2,[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"sort\"]]],null]],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/admin/notifications/template.hbs"
    }
  });

  _exports.default = _default;
});