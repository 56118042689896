define("frontend/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Ember from 'ember';
  var _default = _emberData.default.JSONAPISerializer.extend({
    serializeAttribute(snapshot, json, key, attribute) {
      // do not serialize the attribute!
      if (attribute.options && attribute.options.readOnly) {
        return;
      }

      this._super(...arguments);
    },

    // TODO: we need to remove this completely
    // It should be automatically handled on some level
    // Look at an example in admin
    normalizeArrayResponse(store, primaryModelClass, payload) {
      var normalizedDocument = this._super(...arguments);

      if (payload.meta && payload.meta["page-count"]) {
        payload.meta.total_pages = payload.meta["page-count"];
        delete payload.meta["page-count"];
      }

      return normalizedDocument;
    }

  });

  _exports.default = _default;
});