define("frontend/admin/employee/index/route", ["exports", "frontend/routes/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IndexRoute extends _protected.default {
    beforeModel() {
      var {
        id
      } = this.paramsFor("admin.employee");
      this.transitionTo("admin.employee.visits", id);
    }

  }

  _exports.default = IndexRoute;
});