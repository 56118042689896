define("frontend/admin/overview/route", ["exports", "frontend/routes/protected", "frontend/config/environment"], function (_exports, _protected, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class OverviewRoute extends _protected.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", {
        from: {
          refreshModel: true
        },
        to: {
          refreshModel: true
        }
      });
    }

    model(params) {
      var {
        from
      } = params;
      var {
        to
      } = params;
      var visitsQuery = {
        filter: {
          from,
          to,
          completed: true
        }
      };
      var sellsQuery = {
        filter: {
          visitsFrom: from,
          visitsTo: to,
          visitsCompleted: true
        },
        include: "entity"
      };
      var revenuesQuery = {
        filter: {
          visitsFrom: from,
          visitsTo: to,
          visitsCompleted: true
        },
        include: "entity"
      };
      return Ember.RSVP.hash({
        visits: this.store.query("visit", visitsQuery),
        entities: this.store.findAll("entity"),
        sells: this.store.query("sell", sellsQuery),
        revenues: this.store.query("revenue", revenuesQuery)
      });
    }

    beforeModel(transition) {
      var params = transition.to.queryParams;

      if (!params.from || !params.to) {
        var {
          dateFormat
        } = _environment.default.app;
        this.transitionTo({
          queryParams: {
            from: moment().startOf("day").format(dateFormat),
            to: moment().startOf("day").format(dateFormat)
          }
        });
      }
    }

  }

  _exports.default = OverviewRoute;
});