define("frontend/components/ember-cli-filterable/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isWaiting: false,
    classNames: ["field"],
    // eslint-disable-next-line ember/no-observers
    onFilterStringChange: Ember.observer("filterString", "inputArray.[]", function () {
      this.set("isWaiting", true);
      Ember.run.debounce(this, this.updateFilter, 1000);
    }).on("init"),

    updateFilter() {
      var {
        filterString
      } = this;
      var filteredArray = [];

      if (Ember.isEmpty(filterString)) {
        filteredArray = this.inputArray;
      } else {
        var regexp = new RegExp(filterString, "i");
        var {
          filterBy
        } = this;
        filteredArray = this.inputArray.filter(function (item) {
          var filterProperties = item.getProperties(filterBy);
          var propertyValues = Object.keys(filterProperties).map(key => filterProperties[key]);
          var properties = propertyValues.join();
          return properties.match(regexp);
        });
      }

      this.set("isWaiting", false);
      this.action(filteredArray);
    }

  });

  _exports.default = _default;
});