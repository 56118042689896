define("frontend/admin/employee/items/route", ["exports", "frontend/routes/protected", "frontend/config/environment", "moment"], function (_exports, _protected, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ItemsRoute extends _protected.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", {
        from: {
          refreshModel: true
        },
        to: {
          refreshModel: true
        }
      });
    }

    model(params) {
      var {
        from
      } = params;
      var {
        to
      } = params;
      var employeeId = this.paramsFor("admin.employee").id;
      var query = {
        filter: {
          // eslint-disable-next-line camelcase
          employee_id: employeeId,
          // eslint-disable-next-line camelcase
          visits_from: from,
          // eslint-disable-next-line camelcase
          visits_to: to
        },
        include: "item,item.last-supply"
      };
      var sells = this.store.query("sell", query);
      return Ember.RSVP.hash({
        employee: this.modelFor("admin.employee"),
        sells
      });
    }

    beforeModel(transition) {
      var params = transition.to.queryParams;

      if (!params.from || !params.to) {
        var {
          dateFormat
        } = _environment.default.app;
        this.transitionTo({
          queryParams: {
            from: (0, _moment.default)().startOf("day").format(dateFormat),
            to: (0, _moment.default)().startOf("day").format(dateFormat)
          }
        });
      }
    }

  }

  _exports.default = ItemsRoute;
});