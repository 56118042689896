define("frontend/admin/employee/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var EditController = (_dec = Ember.computed("model.color"), (_class = class EditController extends Ember.Controller {
    init() {
      super.init(...arguments);
      this.colors = ["aquamarine", "blueviolet", "brown", "burlywood", "cadetblue", "chartreuse", "chocolate", "coral", "crimson", "darkturquoise", "dodgerblue", "gold", "goldenrod", "limegreen", "linen", "magenta", "maroon", "cornflowerblue", "slategray", "steelblue", "tomato", "yellowgreen"];
    }

    get selectedStyle() {
      var color = this.get("model.color");
      return {
        color,
        style: Ember.String.htmlSafe("color: ".concat(color))
      };
    }

    get colorStyles() {
      var {
        colors
      } = this;
      return colors.map(function (color) {
        return {
          color,
          style: Ember.String.htmlSafe("color: ".concat(color))
        };
      });
    }

    updateEmployee(employee) {
      var flash = Ember.get(this, "flashMessages");
      var wasNew = this.get("model.isNew");
      var i18n = this.intl;
      return employee.save().then(() => {
        flash.success(i18n.t("admin.employee.savedOk"));

        if (wasNew) {
          this.transitionToRoute("admin.employee.edit", employee.get("id"));
        }
      }, function () {
        flash.danger(i18n.t("admin.employee.savedError"));
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "selectedStyle", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "selectedStyle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "colorStyles", [Ember.computed], Object.getOwnPropertyDescriptor(_class.prototype, "colorStyles"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateEmployee", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "updateEmployee"), _class.prototype)), _class));
  _exports.default = EditController;
});