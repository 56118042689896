define("frontend/calendar/route", ["exports", "frontend/routes/protected", "moment", "frontend/config/environment"], function (_exports, _protected, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  var CalendarRoute = (_class = (_temp = class CalendarRoute extends _protected.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "employees", _descriptor, this);

      _initializerDefineProperty(this, "customers", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _defineProperty(this, "queryParams", {
        from: {
          refreshModel: true
        },
        to: {
          refreshModel: true
        },
        viewName: {}
      });
    }

    model() {
      return Ember.RSVP.hash({
        customers: this.customers,
        employees: this.employees
      });
    }

    beforeModel(transition) {
      var params = transition.to.queryParams;

      if (!params.from || !params.to) {
        var {
          dateFormat
        } = _environment.default.app;
        this.replaceWith({
          queryParams: {
            from: (0, _moment.default)().startOf("week").format(dateFormat),
            to: (0, _moment.default)().endOf("week").format(dateFormat)
          }
        });
      }
    }

    setFromTo(from, to) {
      this.controller.setProperties({
        from,
        to
      });
    }

    setViewName(viewName) {
      this.controller.set("viewName", viewName);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "employees", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "customers", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setFromTo", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "setFromTo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setViewName", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "setViewName"), _class.prototype)), _class);
  _exports.default = CalendarRoute;
});