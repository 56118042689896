define("frontend/customer/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7+geHLYr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[8],[0,\"\\n  \"],[1,[23,0,[\"model\",\"fullName\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"x-note\",[],[[\"@note\",\"@onSave\"],[[23,0,[\"model\",\"note\"]],[28,\"action\",[[23,0,[]],\"updateCustomer\"],null]]]],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui horizontal list\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n    \"],[5,\"x-customer/secret-form\",[],[[\"@customer\",\"@field\",\"@onSave\"],[[23,0,[\"model\"]],\"mail\",[28,\"action\",[[23,0,[]],\"updateCustomer\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n    \"],[5,\"x-customer/secret-form\",[],[[\"@customer\",\"@field\",\"@onSave\"],[[23,0,[\"model\"]],\"phone\",[28,\"action\",[[23,0,[]],\"updateCustomer\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n    \"],[5,\"x-customer/secret-form\",[],[[\"@customer\",\"@field\",\"@onSave\"],[[23,0,[\"model\"]],\"birth\",[28,\"action\",[[23,0,[]],\"updateCustomer\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"customer.visits_length\"],null],false],[0,\"\\n    :\\n    \"],[1,[23,0,[\"model\",\"visitsCount\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"hr\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\"],[5,\"x-customer/visits\",[],[[\"@customerId\",\"@pageSize\"],[[23,0,[\"model\",\"id\"]],50]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/customer/show/template.hbs"
    }
  });

  _exports.default = _default;
});