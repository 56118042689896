define("frontend/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "frontend/config/environment"], function (_exports, _emberData, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    session: Ember.inject.service(),
    namespace: "api/v1",
    host: _environment.default.APP.API_BASE_URL,

    authorize(xhr) {
      var {
        email,
        token
      } = this.get("session.data.authenticated");
      var authData = "Token token=\"".concat(token, "\", email=\"").concat(email, "\"");
      xhr.setRequestHeader("Authorization", authData);
    },

    // TODO: we need to remove this completely
    // It should be automatically handled on some level
    // Look at an example in admin
    buildURL(modelName, id, snapshot, requestType, query) {
      if (query && (query.page || query.per_page) && !Ember.isArray(query.page)) {
        query.page = {
          number: query.page,
          size: query.per_page
        };
        delete query["per_page"];
      }

      return this._super(modelName, id, snapshot, requestType, query);
    }

  });

  _exports.default = _default;
});