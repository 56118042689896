define("frontend/components/x-visit/last-visit/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var LastVisit = (_dec = (0, _component.tagName)(""), _dec2 = Ember.computed("value"), _dec3 = Ember.computed("value"), _dec4 = Ember.computed("days"), _dec(_class = (_class2 = class LastVisit extends Ember.Component {
    get human() {
      var now = moment();
      var created = moment(this.value);
      return created.from(now);
    }

    get days() {
      var now = moment();
      var created = moment(this.value);
      return now.diff(created, "days");
    }

    get class() {
      if (this.days < 30) {
        return "label-success";
      } else if (this.days < 60) {
        return "label-warning";
      } else {
        return "label-danger";
      }
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "human", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "human"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "days", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "days"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "class", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "class"), _class2.prototype)), _class2)) || _class);
  _exports.default = LastVisit;
});