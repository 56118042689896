define("frontend/components/item-entity-sum/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var ItemEntitySum = (_dec = (0, _component.tagName)("tr"), _dec2 = Ember.computed("items.@each.{lastSupply,entity}", "entity.id"), _dec3 = Ember.computed("items.@each.{lastSupply,entity}", "entity.id"), _dec(_class = (_class2 = class ItemEntitySum extends Ember.Component {
    get sellingPriceSum() {
      var {
        items
      } = this;
      var entityId = this.get("entity.id");
      return items.reduce(function (previous, item) {
        var add = 0;

        if (entityId === item.get("entity.id")) {
          if (item.get("unlimited") === false) {
            if (item.get("lastSupply.purchasePrice") > 0) {
              add = item.get("sellingPrice") * (item.get("bought") - item.get("sold"));
            }
          }
        }

        return previous + add;
      }, 0);
    }

    get buyingPriceSum() {
      var {
        items
      } = this;
      var entityId = this.get("entity.id");
      return items.reduce(function (previous, item) {
        var add = 0;

        if (entityId === item.get("entity.id")) {
          if (item.get("unlimited") === false) {
            if (item.get("lastSupply.purchasePrice") > 0) {
              add = item.get("lastSupply.purchasePrice") * (item.get("bought") - item.get("sold"));
            }
          }
        }

        return previous + add;
      }, 0);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "sellingPriceSum", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "sellingPriceSum"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "buyingPriceSum", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "buyingPriceSum"), _class2.prototype)), _class2)) || _class);
  _exports.default = ItemEntitySum;
});