define("frontend/components/x-customer/birthday/component", ["exports", "@ember-decorators/component", "bp-ember-components/lib/customer"], function (_exports, _component, _customer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var Birthday = (_dec = (0, _component.classNames)("ui label"), _dec2 = (0, _component.classNameBindings)("class"), _dec3 = (0, _component.attributeBindings)("title"), _dec4 = (0, _component.tagName)("span"), _dec5 = Ember.computed("value"), _dec6 = Ember.computed("value"), _dec7 = Ember.computed("value"), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class Birthday extends Ember.Component {
    get toFutureHuman() {
      var next = (0, _customer.nextBirthday)(this.value);
      return next.fromNow().toString();
    }

    get class() {
      var days = (0, _customer.daysTillNextBirthday)(this.value);

      if (days < 90) {
        if (days < 30) {
          return "red";
        } else {
          return "orange";
        }
      } else {
        return "grey";
      }
    }

    get title() {
      return this.value;
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "toFutureHuman", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "toFutureHuman"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "class", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "class"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "title", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "title"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = Birthday;
});