define("frontend/admin/import/item/controller", ["exports", "papaparse"], function (_exports, _papaparse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  var ItemController = (_dec = Ember.computed.alias("model.items"), _dec2 = Ember.computed.alias("model.entities"), _dec3 = Ember.computed.filterBy("items", "isNew", true), _dec4 = Ember.computed.oneWay("entities.firstObject"), (_class = (_temp = class ItemController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _defineProperty(this, "sourceCsv", "");

      _initializerDefineProperty(this, "items", _descriptor, this);

      _initializerDefineProperty(this, "entities", _descriptor2, this);

      _initializerDefineProperty(this, "unsavedItems", _descriptor3, this);

      _initializerDefineProperty(this, "selectedEntity", _descriptor4, this);
    }

    parseCsv() {
      var config = {};
      var src = this.sourceCsv;

      var results = _papaparse.default.parse(src, config);

      results.data.forEach(row => {
        var price = parseFloat(row[2]) || 0;
        this.store.createRecord("item", {
          name: row[0],
          barcode: row[1],
          sellingPrice: price,
          isActive: true,
          isService: false,
          entity: this.selectedEntity
        });
      });
    }

    deleteItem(item) {
      item.deleteRecord();
    }

    saveItems() {
      this.unsavedItems.forEach(item => {
        item.save();
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "items", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "entities", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "unsavedItems", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedEntity", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "parseCsv", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "parseCsv"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteItem", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveItems", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "saveItems"), _class.prototype)), _class));
  _exports.default = ItemController;
});