define("frontend/admin/import/item/route", ["exports", "frontend/routes/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ItemRoute extends _protected.default {
    model() {
      return Ember.RSVP.hash({
        items: this.store.peekAll("item"),
        entities: this.store.findAll("entity")
      });
    }

  }

  _exports.default = ItemRoute;
});