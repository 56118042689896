define("frontend/admin/customer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FpjG1SHX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[8],[0,\"\\n  \"],[1,[23,0,[\"model\",\"fullName\"]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui top attached tabular menu\"],[8],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\",\"@model\"],[\"admin.customer.visits\",[23,0,[\"model\",\"id\"]]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"models.visit._modelName\"],[[\"count\"],[2]]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\",\"@model\"],[\"admin.customer.edit\",[23,0,[\"model\",\"id\"]]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"Edit\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui bottom attached active tab segment\"],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/admin/customer/template.hbs"
    }
  });

  _exports.default = _default;
});