define("frontend/admin/customer/visits/route", ["exports", "frontend/routes/protected", "ember-cli-pagination/remote/route-mixin"], function (_exports, _protected, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class VisitsRoute extends _protected.default.extend(_routeMixin.default) {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", {
        sort: {
          refreshModel: true
        }
      });
    }

    model(params) {
      var customer_id = this.paramsFor("admin.customer").id;
      var query = {
        sort: params.sort,
        filter: {
          customer_id
        }
      };
      return this.findPaged("visit", query);
    }

  }

  _exports.default = VisitsRoute;
});