define("frontend/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cNn8jqh1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"title\",true],[8],[1,[23,0,[\"model\",\"title\"]],false],[9],[0,\"\\n\"],[7,\"link\",true],[10,\"rel\",\"shortcut icon\"],[10,\"href\",\"/favicon.png\"],[10,\"type\",\"image/png\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/head.hbs"
    }
  });

  _exports.default = _default;
});