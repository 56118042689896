define("frontend/components/x-visits/employee-share/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var EmployeeShare = (_dec = Ember.computed("visits.@each.employeeShareServices"), _dec2 = Ember.computed("visits.@each.employeeShareSales"), _dec3 = Ember.computed("visits.@each.employeeShare"), (_class = class EmployeeShare extends Ember.Component {
    get sumEmployeeShareService() {
      return this.getWithDefault("visits", []).reduce(function (previousValue, visit) {
        return previousValue + visit.get("employeeShareServices");
      }, 0);
    }

    get sumEmployeeShareSale() {
      return this.getWithDefault("visits", []).reduce(function (previousValue, visit) {
        return previousValue + visit.get("employeeShareSales");
      }, 0);
    }

    get sum() {
      return this.getWithDefault("visits", []).reduce(function (previousValue, visit) {
        return previousValue + visit.get("employeeShare");
      }, 0);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "sumEmployeeShareService", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "sumEmployeeShareService"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sumEmployeeShareSale", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "sumEmployeeShareSale"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sum", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "sum"), _class.prototype)), _class));
  _exports.default = EmployeeShare;
});