define("frontend/visit/route", ["exports", "frontend/routes/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class VisitRoute extends _protected.default {
    model(params) {
      return this.store.findRecord("visit", params.id, {
        include: "customer,employee,sells,sells.item"
      });
    }

  }

  _exports.default = VisitRoute;
});