define("frontend/admin/employee/visits/route", ["exports", "frontend/routes/protected", "frontend/config/environment", "moment"], function (_exports, _protected, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class VisitsRoute extends _protected.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", {
        from: {
          refreshModel: true
        },
        to: {
          refreshModel: true
        }
      });
    }

    model(params) {
      var {
        from
      } = params;
      var {
        to
      } = params;
      var employeeId = this.paramsFor("admin.employee").id;
      var query = {
        filter: {
          // eslint-disable-next-line camelcase
          employee_id: employeeId,
          from,
          to
        },
        include: "customer,sells"
      };
      var visits = this.store.query("visit", query);
      return Ember.RSVP.hash({
        employee: this.modelFor("admin.employee"),
        visits
      });
    }

    beforeModel(transition) {
      var params = transition.to.queryParams;

      if (!params.from || !params.to) {
        var {
          dateFormat
        } = _environment.default.app;
        this.transitionTo({
          queryParams: {
            from: (0, _moment.default)().startOf("day").format(dateFormat),
            to: (0, _moment.default)().startOf("day").format(dateFormat)
          }
        });
      }
    }

  }

  _exports.default = VisitsRoute;
});