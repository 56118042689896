define("frontend/visit/print/route", ["exports", "frontend/routes/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PrintRoute extends _protected.default {
    model() {
      var {
        id
      } = this.paramsFor("visit");
      var params = {
        reload: true,
        include: "employee,sells,revenues,employee.company,sells.item,sells.item.entity,revenues.entity"
      };
      return this.store.findRecord("visit", id, params);
    }

  }

  _exports.default = PrintRoute;
});