define("frontend/models/invoice", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    start: _emberData.default.attr("date"),
    end: _emberData.default.attr("date"),
    amount: _emberData.default.attr("number"),
    sentSms: _emberData.default.attr("number"),
    isPaid: _emberData.default.attr("boolean"),
    note: _emberData.default.attr("string"),
    notifications: _emberData.default.hasMany(),
    company: _emberData.default.belongsTo()
  });

  _exports.default = _default;
});