define("frontend/login/route", ["exports", "frontend/routes/public"], function (_exports, _public) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LoginRoute extends _public.default {
    model() {
      return {
        username: null,
        password: null
      };
    }

  }

  _exports.default = LoginRoute;
});