define("frontend/visit/show/page-object", ["exports", "ember-cli-page-object", "ember-power-select/test-support"], function (_exports, _emberCliPageObject, _testSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = VisitShowPage;

  function VisitShowPage() {
    return (0, _emberCliPageObject.create)({
      visit: (0, _emberCliPageObject.visitable)("/visit/:id"),
      form: {
        scope: "[data-test-sell-form]",

        selectItem(optionText) {
          return (0, _testSupport.selectChoose)(".data-test-select-item", optionText);
        },

        count: (0, _emberCliPageObject.value)("[name='sell[count]']"),
        price: (0, _emberCliPageObject.value)("[name='sell[price]']"),
        discount: (0, _emberCliPageObject.value)("[name='sell[discount]']"),
        sum: (0, _emberCliPageObject.text)("[data-test-sell-sum]"),
        setCount: (0, _emberCliPageObject.fillable)("[name='sell[count]']"),
        setPrice: (0, _emberCliPageObject.fillable)("[name='sell[price]']"),
        setDiscount: (0, _emberCliPageObject.fillable)("[name='sell[discount]']"),
        submit: (0, _emberCliPageObject.clickable)("[data-test-sell-add]")
      },
      sells: (0, _emberCliPageObject.collection)("[data-test-sell]", {
        itemName: (0, _emberCliPageObject.text)("[data-test-item-name]"),
        count: (0, _emberCliPageObject.text)("[data-test-count]"),
        price: (0, _emberCliPageObject.text)("[data-test-price]"),
        discount: (0, _emberCliPageObject.text)("[data-test-discount]"),
        sum: (0, _emberCliPageObject.text)("[data-test-sum]")
      }),
      note: (0, _emberCliPageObject.value)("[data-test-visit-note]")
    });
  }
});