define("frontend/admin/visit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  var VisitController = (_dec = Ember.computed.empty("model.sells"), _dec2 = Ember.computed.alias("model.completed"), (_class = (_temp = class VisitController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "isDeleteVisible", _descriptor, this);

      _initializerDefineProperty(this, "isReopenVisible", _descriptor2, this);
    }

    deleteVisit(visit) {
      var flash = this.flashMessages; // eslint-disable-next-line camelcase

      var customer_id = visit.get("customer.id");
      visit.deleteRecord();
      visit.save().then(() => {
        flash.success("Visit deleted");
        this.transitionToRoute("admin.customer", customer_id);
      }, function (response) {
        flash.danger("Customer cannot be deleted! ".concat(response.responseText));
      });
    }

    reopenVisit(visit) {
      var i18n = this.intl;
      var flash = this.flashMessages;
      visit.set("completed", false);
      visit.save().then(() => {
        flash.success(i18n.t("admin.visit.reopened"));
        this.transitionToRoute("visit", visit.id);
      }, function () {
        flash.danger(i18n.t("admin.visit.reopenFailed"));
      });
    }

    updateNote(field, value) {
      var flash = this.flashMessages;
      var visit = this.model;
      visit.set(field, value);
      visit.save().then(() => {
        flash.success("admin.visit.saved");
      }, () => {
        flash.danger("admin.visit.saveFailed");
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isDeleteVisible", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isReopenVisible", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "deleteVisit", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteVisit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reopenVisit", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "reopenVisit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateNote", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "updateNote"), _class.prototype)), _class));
  _exports.default = VisitController;
});