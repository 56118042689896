define("frontend/admin/employee/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c9bpEXpr",
    "block": "{\"symbols\":[],\"statements\":[[5,\"page-header\",[],[[\"@backLink\",\"@backText\",\"@headingText\"],[\"admin.employees\",[28,\"t\",[\"admin.employee.back\"],null],[28,\"t\",[\"admin.employee.heading\"],[[\"name\"],[[23,0,[\"model\",\"fullName\"]]]]]]]],[0,\"\\n\\n\"],[4,\"unless\",[[23,0,[\"isNew\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui top attached tabular menu\"],[8],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\",\"@model\"],[\"admin.employee.visits\",[23,0,[\"model\",\"id\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"admin.employee.visits\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\",\"@model\"],[\"admin.employee.items\",[23,0,[\"model\",\"id\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"admin.employee.itemsList\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\",\"@model\"],[\"admin.employee.edit\",[23,0,[\"model\",\"id\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"admin.employee.edit\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"ui bottom \",[28,\"unless\",[[23,0,[\"isNew\"]],\"attached\"],null],\" active tab segment\"]]],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/admin/employee/template.hbs"
    }
  });

  _exports.default = _default;
});