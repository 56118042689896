define("frontend/cashbook/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KL6t3AOJ",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[7,\"h2\",true],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"cashbook.heading\"],null],false],[0,\":\\n  \"],[5,\"money\",[],[[\"@value\"],[[23,0,[\"cashbookBalance\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"cashbook.disclaimer\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"validated-form\",[],[[\"@model\",\"@on-submit\"],[[28,\"changeset\",[[23,0,[\"model\",\"newEntry\"]]],null],[28,\"perform\",[[23,0,[\"persistNewEntry\"]]],null]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"input\"]],[],[[\"@name\",\"@required\",\"@label\",\"@type\",\"@step\"],[\"amount\",true,[28,\"t\",[\"models.cashbookEntry.amount\"],null],\"number\",0.01]]],[0,\"\\n  \"],[6,[23,1,[\"input\"]],[],[[\"@name\",\"@label\"],[\"note\",[28,\"t\",[\"models.cashbookEntry.note\"],null]]]],[0,\"\\n  \"],[6,[23,1,[\"submit\"]],[],[[\"@disabled\"],[[23,1,[\"loading\"]]]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\\n\"],[5,\"data-grid\",[],[[\"@columns\",\"@rows\",\"@fillColumnIndex\",\"@sort\",\"@onUpdateSort\",\"@rowClass\"],[[23,0,[\"columns\"]],[23,0,[\"rows\"]],2,[23,0,[\"sort\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"sort\"]]],null]],null],\"kind\"]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/cashbook/template.hbs"
    }
  });

  _exports.default = _default;
});