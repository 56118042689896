define("frontend/components/x-customer/visits/visit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Visit extends Ember.Component {}

  _exports.default = Visit;
});