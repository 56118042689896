define("frontend/integrations/bing", ["exports", "ember-cli-analytics/integrations/bing"], function (_exports, _bing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _bing.default;
    }
  });
});