define("frontend/components/reservation-modal/component", ["exports", "@ember-decorators/component", "semantic-ui-ember/components/ui-modal"], function (_exports, _component, _uiModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var ReservationModal = (_dec = (0, _component.classNames)("reservation"), _dec2 = Ember.computed("event.{customer.id,customer.phone,willNotify}"), _dec3 = Ember.computed("event.{employee,customer}"), _dec4 = Ember.computed("event.{employee.content,start,end}", "invalidNotification"), _dec(_class = (_class2 = (_temp = class ReservationModal extends _uiModal.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "name", "reservation");
    }

    get invalidNotification() {
      var customerSelected = !Ember.isEmpty(this.get("event.customer.id"));
      var shouldNotify = this.get("event.willNotify");
      var noPhone = Ember.isEmpty(this.get("event.customer.phone"));
      return shouldNotify && (!customerSelected || noPhone);
    }

    get isCreateVisitButtonDisabled() {
      var hasNoCustomer = Ember.isEmpty(this.get("event.customer.content"));
      var hasNoEmployee = Ember.isEmpty(this.get("event.employee.content"));
      return hasNoCustomer || hasNoEmployee;
    }

    get isSaveButtonDisabled() {
      var hasNoEmployee = Ember.isEmpty(this.get("event.employee.content"));
      var hasNoStart = this.getWithDefault("event.start", "").toString() === "Invalid Date";
      var hasNoEnd = this.getWithDefault("event.end", "").toString() === "Invalid Date";
      var invalidNotification = this.invalidNotification;
      return hasNoEmployee || hasNoStart || hasNoEnd || invalidNotification;
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "invalidNotification", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "invalidNotification"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isCreateVisitButtonDisabled", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "isCreateVisitButtonDisabled"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isSaveButtonDisabled", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "isSaveButtonDisabled"), _class2.prototype)), _class2)) || _class);
  _exports.default = ReservationModal;
});