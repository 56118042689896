define("frontend/visits/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  var VisitsController = (_dec = Ember.computed.filterBy("model.visits", "completed", false), _dec2 = Ember.computed.oneWay("model.employees.active"), _dec3 = Ember.computed.oneWay("model.customers.active"), _dec4 = Ember.computed("heap.{customer,employee}"), (_class = (_temp = class VisitsController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "settings", _descriptor, this);

      _initializerDefineProperty(this, "openVisits", _descriptor2, this);

      _initializerDefineProperty(this, "activeEmployees", _descriptor3, this);

      _initializerDefineProperty(this, "activeCustomers", _descriptor4, this);
    }

    get addButonDisabled() {
      return !this.get("heap.customer") || !this.get("heap.employee");
    }

    init() {
      super.init(...arguments);
      this.heap = {};
    }

    createVisit() {
      var visit = this.store.createRecord("visit", {
        customer: this.get("heap.customer"),
        employee: this.get("heap.employee"),
        employeeShareOfSales: this.get("heap.employee.shareOfSales"),
        employeeShareOfServices: this.get("heap.employee.shareOfServices")
      });
      var flash = Ember.get(this, "flashMessages");
      var i18n = this.intl;
      visit.save().then(() => {
        flash.success(i18n.t("visit.creatingVisitSucceeded"));
        this.transitionToRoute("visit.show", visit.id);
      }).catch(() => {
        flash.danger(i18n.t("visit.creatingVisitFailed"));
        this.store.deleteRecord(visit);
      });
      this.set("heap", {});
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "settings", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "openVisits", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "activeEmployees", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "activeCustomers", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addButonDisabled", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "addButonDisabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createVisit", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "createVisit"), _class.prototype)), _class));
  _exports.default = VisitsController;
});