define("frontend/components/x-item/search/component", ["exports", "@ember-decorators/component", "ember-power-select/utils/group-utils"], function (_exports, _component, _groupUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  var Search = (_dec = (0, _component.tagName)(""), _dec(_class = class Search extends Ember.Component {
    itemMatcher(item, needle) {
      var needles = (0, _groupUtils.stripDiacritics)(needle).toUpperCase().split(" ");
      var string = (0, _groupUtils.stripDiacritics)(item.get("scannerString")).toUpperCase();

      for (var i = 0, len = needles.length; i < len; i++) {
        if (!string.includes(needles[i])) {
          return -1;
        }
      }

      return 1;
    }

  }) || _class);
  _exports.default = Search;
});