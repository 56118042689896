define("frontend/object/transform", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _Transform extends _transform.default {
    deserialize(serialized) {
      return Ember.isBlank(serialized) ? {} : serialized;
    }

    serialize(deserialized) {
      return Ember.isBlank(deserialized) ? {} : deserialized;
    }

  }

  _exports.default = _Transform;
});