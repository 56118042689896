define("frontend/components/ember-power-select/component", ["exports", "@ember-decorators/component", "ember-power-select/components/power-select"], function (_exports, _component, _powerSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  var _EmberPowerSelect = (_dec = (0, _component.tagName)("div"), _dec(_class = class _EmberPowerSelect extends _powerSelect.default {}) || _class);

  _exports.default = _EmberPowerSelect;
});