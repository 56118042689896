define("frontend/admin/import/customer/route", ["exports", "frontend/routes/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CustomerRoute extends _protected.default {
    model() {
      return Ember.RSVP.hash({
        customers: this.store.peekAll("customer")
      });
    }

  }

  _exports.default = CustomerRoute;
});