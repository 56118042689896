define("frontend/admin/employee/visits/controller", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  var VisitsController = (_dec = Ember.computed("model.visits.@each.price"), _dec2 = Ember.computed("model.visits.@each.price"), _dec3 = Ember.computed("model.visits.@each.price"), (_class = (_temp = class VisitsController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", ["from", "to"]);

      _defineProperty(this, "from", null);

      _defineProperty(this, "to", null);

      _initializerDefineProperty(this, "excel", _descriptor, this);
    }

    get sumPrice() {
      return this.getWithDefault("model.visits", []).reduce(function (previousValue, visit) {
        return previousValue + visit.get("price");
      }, 0);
    }

    get sumAmountSales() {
      return this.getWithDefault("model.visits", []).reduce(function (previousValue, visit) {
        return previousValue + visit.get("amountSales");
      }, 0);
    }

    get sumAmountServices() {
      return this.getWithDefault("model.visits", []).reduce(function (previousValue, visit) {
        return previousValue + visit.get("amountServices");
      }, 0);
    }

    updateDates(startDate, endDate) {
      this.setProperties({
        from: moment(startDate).format(_environment.default.app.dateFormat),
        to: moment(endDate).format(_environment.default.app.dateFormat)
      });
    }

    exportVisits() {
      var visits = this.model.visits.map(function (visit) {
        return [visit.createdAt.toString(), visit.customer.get('fullName'), visit.amountSales, visit.amountServices, visit.price, visit.employeeShareSales, visit.employeeShareServices];
      });
      var i18n = this.intl;
      var headers = [[i18n.t("visit.createdAt").toString(), i18n.t("visit.customer").toString(), i18n.t("visit.amountSales").toString(), i18n.t("visit.amountServices").toString(), i18n.t("visit.price").toString(), i18n.t("visit.employeeShareSales").toString(), i18n.t("visit.employeeShareServices").toString()]];
      var data = headers.concat(visits);
      var sheetName = 'sheet1';
      var fileName = "".concat(this.model.employee.lastName, " ").concat(this.model.employee.firstName, ".xlsx");
      this.excel.export(data, {
        sheetName,
        fileName
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "excel", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sumPrice", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "sumPrice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sumAmountSales", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "sumAmountSales"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sumAmountServices", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "sumAmountServices"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateDates", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "updateDates"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "exportVisits", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "exportVisits"), _class.prototype)), _class));
  _exports.default = VisitsController;
});