define("frontend/customer/new/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var NewController = (_class = class NewController extends Ember.Controller {
    init() {
      super.init(...arguments);
      this.genders = ["male", "female"];
    }

    createCustomer() {
      var customer = this.model;
      var flash = this.flashMessages;
      customer.save().then(newCustomer => {
        var firstName = newCustomer.get("firstName");
        var lastName = newCustomer.get("lastName");
        flash.success("".concat(firstName, " ").concat(lastName, " created"));
        this.transitionToRoute("customer.show", newCustomer);
      }, err => {
        flash.danger("Cannot save customer: ".concat(err.message));
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "createCustomer", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "createCustomer"), _class.prototype)), _class);
  _exports.default = NewController;
});