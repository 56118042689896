define("frontend/admin/visit/route", ["exports", "frontend/routes/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class VisitRoute extends _protected.default {
    model(params) {
      var query = {
        include: "employee,customer,sells,revenues,sells.item,revenues.entity"
      };
      return this.store.findRecord("visit", params.id, query);
    }

  }

  _exports.default = VisitRoute;
});