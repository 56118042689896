define("frontend/admin/items/stock/controller", ["exports", "ember-concurrency", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _emberConcurrency, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  var StockController = (_dec = (0, _emberConcurrency.task)(function* (filter) {
    yield (0, _emberConcurrency.timeout)(400);
    this.set("filter", filter);
  }).restartable(), (_class = (_temp = class StockController extends Ember.Controller.extend(_fileSaver.default) {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", ["sort", "filter", "isActive"]);

      _defineProperty(this, "sort", "name");

      _defineProperty(this, "filter", "");

      _defineProperty(this, "isActive", "true");

      _initializerDefineProperty(this, "excel", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);
    }

    exportItems() {
      var query = {
        filter: {
          isActive: this.isActive,
          fulltext: this.filter
        },
        page: 1,
        perPage: 1000,
        sort: this.sort
      };
      var allItems = this.store.query("item", query);
      allItems.then(items => {
        var itemsHash = items.map(function (c) {
          return [c.get("name"), c.get("barcode"), c.get("sellingPrice"), c.get("bought") - c.get("sold")];
        });
        var i18n = this.intl;
        var headers = [[i18n.t("admin.items.name").toString(), i18n.t("admin.items.barcode").toString(), i18n.t("admin.items.sellingPrice").toString(), i18n.t("admin.items.stock").toString()]];
        var data = headers.concat(itemsHash);
        var sheetName = "sheet1";
        var fileName = "sklad.xlsx";
        this.excel.export(data, {
          sheetName,
          fileName
        });
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "excel", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "exportItems", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "exportItems"), _class.prototype)), _class));
  _exports.default = StockController;
});