define("frontend/admin/notifications/route", ["exports", "frontend/routes/protected", "frontend/config/environment", "ember-cli-pagination/remote/route-mixin"], function (_exports, _protected, _environment, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class NotificationsRoute extends _protected.default.extend(_routeMixin.default) {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", {
        sort: {
          refreshModel: true
        },
        from: {
          refreshModel: true
        },
        to: {
          refreshModel: true
        }
      });

      _defineProperty(this, "sort", 'sendAt');
    }

    model(queryParams) {
      var {
        from,
        to
      } = queryParams;
      var params = {
        filter: {
          from,
          to
        }
      };
      return this.findPaged("notification", params);
    }

    beforeModel(transition) {
      var params = transition.to.queryParams;

      if (!params.from || !params.to) {
        var {
          dateFormat
        } = _environment.default.app;
        this.transitionTo({
          queryParams: {
            from: moment().startOf("week").format(dateFormat),
            to: moment().endOf("week").format(dateFormat)
          }
        });
      }
    }

  }

  _exports.default = NotificationsRoute;
});