define("frontend/models/payment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    start: _emberData.default.attr("date", {
      defaultValue: () => new Date()
    }),
    end: _emberData.default.attr("date", {
      defaultValue: () => new Date()
    }),
    amount: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    note: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    updatedAt: _emberData.default.attr("date"),
    company: _emberData.default.belongsTo("company")
  });

  _exports.default = _default;
});